import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {DataGridPro} from '@mui/x-data-grid-pro'
import {KTSVG} from '_metronic/helpers'
import dayjs, {Dayjs} from 'dayjs'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {CheckListChart} from '../charts/checklistChart'
import './../scrollbar.css'

import MeterariumTabPanel from 'pages/common/TabPanel'
import {
  customerCheckListChartFilter,
  customerCheckListFilter,
} from 'pages/customer/dataConfig/declearFilters'
import {getCheckList, getCheckListChart} from 'utils/apiCalling/api'
import {a11yProps, scrollTop, tapHandleChange} from 'utils/func/justUtils'
// @ts-ignore
import {customerCheckListColumns} from 'pages/customer/dataConfig/declearColumns'
import {customerCheckListGroup} from 'pages/customer/dataConfig/declearGroupingModel'
import {customerCheckListRows} from 'pages/customer/dataConfig/declearRows'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumChartLayout from 'utils/designSytem/MeterariumChartLayout'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {toolTipText} from 'utils/toolTips/toolTipText'

const CustomerChecklist = () => {
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [value, setValue] = useState(0)
  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const [checkList, setCheckList] = useState<any>()
  const [useChart, setUseChart] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<any>(null)
  const [filter, setFilter] = useState<any>(customerCheckListFilter)
  const [chartFilter, setChartFilter] = useState<any>(customerCheckListChartFilter)

  const getCheckListCall = async () => {
    await getCheckList(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setCheckList(res)
      },
      count: (count: any) => {
        setTotalCount(count)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }
  const getCheckListChartListCall = () => {
    getCheckListChart(officeInfo, cooperInfo, chartFilter, {
      success: (res: any) => {
        setUseChart(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const rows = customerCheckListRows(checkList)

  useEffect(() => {
    scrollTop()
    if (value === 0) {
      getCheckListCall()
    } else {
      getCheckListChartListCall()
    }
  }, [
    value,
    filter.start,
    filter.end,
    filter.flag,
    officeInfo,
    cooperInfo,
    filter.page,
    filter.rowPerPage,
    chartFilter.end,
    chartFilter.flag,
  ])
  useEffect(() => {
    if (value === 1) {
      setChartFilter({...chartFilter, flag: 'month', start: '', end: dayjs().format('YYYYMM')})
    }
    return
  }, [value])
  useEffect(() => {
    if (value === 0) {
      setDate(dayjs())
    }
  }, [filter.flag])

  return (
    <>
      <MeterariumTabPanel id={'checklist-table'} value={value} index={0}>
        <>
          <MeterariumPageLayout
            headerDescription={toolTipText.customerCheckList.description}
            tooltipText={toolTipText.customerCheckList.text}
            filter={filter}
            id={'customer-checklist'}
            setFilter={setFilter}
            searchCallback={getCheckListCall}
            total={totalCount}
            control={['rangeDate']}
            actions={[]}
            searchOptions={['customerNumber', 'customerName', 'address', 'managerName']}
            date={date}
            setDate={setDate}
            checkData={null}
            onDelete={null}
            onCancel={null}
            setTypeName={null}
            pannel={
              <Box sx={{borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff'}}>
                <Tabs
                  value={value}
                  onChange={(e, newValue) => tapHandleChange(newValue, setValue)}
                  aria-label='check-list-tab'
                >
                  <Tab label='이미지 한눈에 보기' {...a11yProps(0)} className={'fw-bolder'} />
                  <Tab label='차트로 간략하게 보기' className={'fw-bolder'} {...a11yProps(1)} />
                </Tabs>
              </Box>
            }
          >
            <DataGridPro
              rowHeight={220}
              headerHeight={30}
              scrollbarSize={60}
              autoHeight={true}
              columnGroupingModel={customerCheckListGroup}
              experimentalFeatures={{columnGrouping: true}}
              rows={rows}
              columns={customerCheckListColumns}
              disableColumnMenu
              loading={loading}
              paginationMode={'server'}
              hideFooterPagination={true}
              pagination={true}
              hideFooter={true}
            />
            <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
          </MeterariumPageLayout>
        </>
      </MeterariumTabPanel>
      <MeterariumTabPanel id={'checklist-chart'} value={value} index={1}>
        <MeterariumChartLayout
          headerDescription={toolTipText.customerCheckList.description}
          tooltipText={toolTipText.customerCheckList.text}
          filter={chartFilter}
          id={'customer-checklist-chart'}
          setFilter={setChartFilter}
          searchCallback={getCheckListChartListCall}
          total={totalCount}
          control={['period', 'chooseMonth']}
          actions={[]}
          searchOptions={[]}
          date={date}
          setDate={setDate}
          checkData={null}
          onDelete={null}
          onCancel={null}
          setTypeName={null}
          pannel={
            <Box sx={{borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff'}}>
              <Tabs
                value={value}
                onChange={(e, newValue) => tapHandleChange(newValue, setValue)}
                aria-label='check-list-tab'
              >
                <Tab label='이미지 한눈에 보기' {...a11yProps(0)} className={'fw-bolder'} />
                <Tab label='차트로 간략하게 보기' className={'fw-bolder'} {...a11yProps(1)} />
              </Tabs>
            </Box>
          }
        >
          <div className={'row row-cols-2 mt-5'} id={'content'}>
            <div id={'chart-section'} className={'col-lg mt-3'}>
              {useChart ? (
                <CheckListChart
                  className={'w-100 v-100'}
                  useChart={useChart}
                  filter={chartFilter}
                  loading={loading}
                  value={value}
                />
              ) : (
                <div className='notice d-flex gap-5 bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex justify-content-center gap-3 my-3'>
                    <div className='spinner-border' role='status' />
                  </div>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>로딩 중 입니다.</h4>
                      <div className='fs-6 text-gray-600'>
                        <span>데이터 수집 중입니다. 감사합니다.</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </MeterariumChartLayout>
      </MeterariumTabPanel>
    </>
  )
}
export default CustomerChecklist
