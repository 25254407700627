import {DataGridPro} from '@mui/x-data-grid-pro'
import ExcelDownLoadModal from 'pages/common/ExcelDownloadModal'
import {customerMinimumFlowColumns} from 'pages/customer/dataConfig/declearColumns'
import {customerMinimumFlowFilter} from 'pages/customer/dataConfig/declearFilters'
import {customerMinimumFlowRows} from 'pages/customer/dataConfig/declearRows'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getMinimumFlowList} from 'utils/apiCalling/api'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {scrollTop} from 'utils/func/justUtils'
import {toolTipText} from 'utils/toolTips/toolTipText'

const CustomerMinimumFlow = (props: any) => {
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [minimumList, setMinimumList] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<any>(null)
  const [filter, setFilter] = useState<any>(customerMinimumFlowFilter)
  const getMinimumFlowListCall = () => {
    getMinimumFlowList(officeInfo, cooperInfo, filter, {
      success: (res: any) => {
        setMinimumList(res)
      },
      count: (count: any) => {
        setTotalCount(count)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setLoading(loading)
      },
    })
  }

  const columns: any = customerMinimumFlowColumns(filter)
  const rows = customerMinimumFlowRows(minimumList)

  useEffect(() => {
    scrollTop()
    getMinimumFlowListCall()
  }, [
    filter.date,
    filter.start,
    filter.end,
    officeInfo,
    cooperInfo,
    filter.flag,
    filter.page,
    filter.rowPerPage,
  ])
  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerMinimumFlow.description}
        tooltipText={toolTipText.customerMinimumFlow.text}
        filter={filter}
        id={'customer-minimumFlow'}
        setFilter={setFilter}
        searchCallback={getMinimumFlowListCall}
        total={totalCount}
        control={['useSwitch', 'selectDate', 'timeRange']}
        actions={['download']}
        searchOptions={['customerNumber', 'customerName', 'address', 'managerName']}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        <DataGridPro
          rows={rows}
          rowCount={rows?.length}
          columns={columns}
          rowHeight={240}
          disableColumnMenu
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
          loading={loading}
          autoHeight={true}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
      <ExcelDownLoadModal type={'최소유량 조회'} />
    </>
  )
}
export default CustomerMinimumFlow
