import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {PageTitle} from '_metronic/layout/core'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import CountUp from 'react-countup'
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {getMarker, getWeather} from 'utils/apiCalling/api'
import './css/main.css'
import DashboardChart from './modules/DashboardChart'
import MapContainer from './modules/MapContainer'
import StickyHeadTable from './modules/MapSearchTable'
import NoListKakaoMap from './modules/NoListKaKaoMap'
/*icons*/
import {
  AcUnitTwoTone,
  UmbrellaTwoTone,
  WaterTwoTone,
  WbCloudyTwoTone,
  WbSunnyTwoTone,
} from '@mui/icons-material'
import AddTaskIcon from '@mui/icons-material/AddTask'
import RegistrationIcon from '@mui/icons-material/AppRegistration'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import AsIcon from '@mui/icons-material/Build'
import InstallIcon from '@mui/icons-material/BuildCircle'
import MonitoringIcon from '@mui/icons-material/ConnectedTv'
import InstallPage from '@mui/icons-material/Construction'
import SituationIcon from '@mui/icons-material/ContentPasteSearch'
import CodeIcon from '@mui/icons-material/Dns'
import CheckReportIcon from '@mui/icons-material/Filter9Plus'
import CheckListIcon from '@mui/icons-material/FormatListBulleted'
import HandymanIcon from '@mui/icons-material/Handyman'
import MinimumFlowIcon from '@mui/icons-material/HistoryToggleOff'
import HomeIcon from '@mui/icons-material/Home'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import InfoIcon from '@mui/icons-material/Info'
import SystemIcon from '@mui/icons-material/IntegrationInstructions'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import AccountIcon from '@mui/icons-material/ManageAccounts'
import DeviceIcon from '@mui/icons-material/MobileFriendly'
import ClientIcon from '@mui/icons-material/People'
import OfficeSettingIcon from '@mui/icons-material/PermDataSetting'
import SettingIcon from '@mui/icons-material/SettingsSuggest'
import ReportIcon from '@mui/icons-material/Summarize'
import LeakCustomer from '@mui/icons-material/WaterDamage'
import AdminDashboard from 'pages/dashboard/modules/adminDashboard'

const DashboardPage = (props: any) => {
  const [apiData, setApiData] = useState<any>()
  const isMobile = isMobileDevice()
  const [open, setOpen] = React.useState(true)
  const [deviceOpen, setDeviceOpen] = React.useState(true)
  const [taskOpen, setTaskOpen] = React.useState(true)
  const [accountOpen, setAccountOpen] = React.useState(true)
  const [systemOpen, setSystemOpen] = React.useState(true)
  const [settingOpen, setSettingOpen] = React.useState(true)
  const [installOpen, setInstallOpen] = React.useState(true)
  const navigate = useNavigate()

  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const [cluster, setCluster] = useState<any>(null)
  const [cityName, setCityName] = useState<any>(null)
  const weatherIcon = (icon: string) => {
    switch (icon?.substring(0, 2)) {
      case '01':
        return <WbSunnyTwoTone style={{fontSize: 30, color: '#ee7d1e'}} />
      case '02':
        return <WbCloudyTwoTone style={{fontSize: 30, color: '#969696'}} />
      case '03':
        return <WbCloudyTwoTone style={{fontSize: 30, color: '#969696'}} />
      case '04':
        return <WbCloudyTwoTone style={{fontSize: 30, color: '#969696'}} />
      case '09':
        return <UmbrellaTwoTone style={{fontSize: 30, color: '#0070b7'}} />
      case '10':
        return <UmbrellaTwoTone style={{fontSize: 30, color: '#0070b7'}} />
      case '11':
        return <UmbrellaTwoTone style={{fontSize: 30, color: '#0070b7'}} />
      case '13':
        return <AcUnitTwoTone style={{fontSize: 30, color: '#c5c4c4'}} />
      case '50':
        return <WaterTwoTone style={{fontSize: 30, color: '#969696'}} />
      default:
        return <WbCloudyTwoTone style={{fontSize: 30, color: '#969696'}} />
    }
  }
  const customerHandleClick = () => {
    setOpen(!open)
  }
  const deviceHandleClick = () => {
    setDeviceOpen(!deviceOpen)
  }
  const taskHandleClick = () => {
    setTaskOpen(!taskOpen)
  }
  const accountHandleClick = () => {
    setAccountOpen(!accountOpen)
  }
  const systemHandleClick = () => {
    setSystemOpen(!systemOpen)
  }
  const settingHandleClick = () => {
    setSettingOpen(!settingOpen)
  }
  const installOpenClick = () => {
    setInstallOpen(!installOpen)
  }

  useEffect(() => {
    if (userInfo.id) {
      getWeather(setApiData)
      getMarker(officeInfo, cooperInfo, setCluster, setCityName)
    }
  }, [officeInfo, cooperInfo])

  return (
    <>
      {!isMobile && (
        <>
          <div className={'card '}>
            <div className={'card-body d-flex justify-content-between py-3 '}>
              <div
                id={'weather-box'}
                className={'col-lg-3 flex-fill d-flex justify-content-evenly text-center bg-white '}
              >
                <div className={'d-flex col align-self-center justify-content-center gap-3'}>
                  <div className={'align-self-center'}>
                    <span className={'text-muted fs-3 fw-bolder'}>날씨</span>
                  </div>
                  {weatherIcon(apiData?.weather[0]?.icon)}
                </div>

                <div className={' col  align-self-center'}>
                  <span className={'text-muted fs-3 fw-bolder align-self-center me-3'}>온도</span>
                  <span className={' fs-3 fw-bolder align-self-center '}>
                    <CountUp end={Number(apiData?.main?.temp)} duration={1.2} decimals={1} /> °C
                  </span>
                </div>
                <div className={' col  align-self-center'}>
                  <span className={'text-muted fs-3 fw-bolder align-self-center me-3'}>
                    체감 온도
                  </span>
                  <span className={' fs-3 fw-bolder align-self-center '}>
                    <CountUp end={apiData?.main?.feels_like} duration={1.2} decimals={1} /> °C
                  </span>
                </div>
                <div className={' col  align-self-center'}>
                  <span className={'text-muted fs-3 fw-bolder align-self-center me-3'}>습도</span>
                  <span className={' fs-3 fw-bolder align-self-center '}>
                    <CountUp end={apiData?.main?.humidity} duration={1.2} /> %
                  </span>
                </div>
                <div className={' col  align-self-center'}>
                  <span className={'text-muted fs-3 fw-bolder align-self-center me-3'}>
                    바람 세기
                  </span>
                  <span className={' fs-3 fw-bolder align-self-center '}>
                    <CountUp end={apiData?.wind?.speed} duration={1.2} decimals={2} /> m/s
                  </span>
                </div>
              </div>
            </div>
          </div>
          <DashboardChart />
          <div className={'container-fluid d-flex row-cols-2 gap-0 rounded-3 gap-4 px-0 pe-3'}>
            <div className={'col-8 h-100 border border-2  rounded-2'} id={'dashboard-section'}>
              {cluster?.length > 0 &&
                ['yit', 'all'].some((keyword) => officeInfo?.value?.includes(keyword)) && (
                  <div className={'h-100'}>
                    <AdminDashboard />
                  </div>
                )}
              {cluster?.length > 0 &&
                !['yit', 'all'].some((keyword) => officeInfo?.value?.includes(keyword)) && (
                  <MapContainer cluster={cluster} cityName={cityName} />
                )}
              {cluster?.length === 0 && <NoListKakaoMap />}
            </div>
            <div
              className={'col-4'}
              style={{height: userInfo.accountType?.includes('sys') ? 650 : '100%'}}
            >
              <StickyHeadTable />
            </div>
          </div>
          <ToastContainer />
        </>
      )}
      {isMobile && (
        <>
          {userInfo?.accountType === 'installer' && (
            <>
              <div className={'card card-body w-100 align-items-center my-5'}>
                <Link to={'/install/connect'} className={'w-100'}>
                  <Button variant={'outlined'} className={'h-40px w-100'}>
                    설치자 페이지 바로가기
                  </Button>
                </Link>
                <div className={''}>
                  {_.size(apiData) <= 0 && (
                    <div className={'d-flex justify-content-center align-items-center w-100 mt-5'}>
                      <div className={'spinner-border text-primary'} role={'status'}>
                        <span className={'visually-hidden'}>Loading...</span>
                      </div>
                    </div>
                  )}
                  {_.size(apiData) > 0 && (
                    <div id={'weather-box'} className={'row  text-start gap-4 ms-2 mt-5'}>
                      <div className={'  align-self-center mb-4'}>
                        <span className={'text-muted fs-6 fw-bolder me-3'}>날씨</span>
                        {weatherIcon(apiData?.weather[0]?.icon)}
                      </div>

                      <div className={'align-self-center mb-4'}>
                        <span className={'text-muted fs-6 fw-bolder align-self-center me-3'}>
                          온도
                        </span>
                        <span className={' fs-6 fw-bolder align-self-center '}>
                          <CountUp end={Number(apiData?.main?.temp)} duration={1.2} decimals={1} />{' '}
                          °C
                        </span>
                      </div>
                      <div className={'align-self-center mb-4'}>
                        <span className={'text-muted fs-6 fw-bolder align-self-center me-3'}>
                          체감 온도
                        </span>
                        <span className={' fs-6 fw-bolder align-self-center '}>
                          <CountUp end={apiData?.main?.feels_like} duration={1.2} decimals={1} /> °C
                        </span>
                      </div>
                      <div className={'align-self-center mb-4'}>
                        <span className={'text-muted fs-6 fw-bolder align-self-center me-3'}>
                          습도
                        </span>
                        <span className={' fs-6 fw-bolder align-self-center '}>
                          <CountUp end={apiData?.main?.humidity} duration={1.2} /> %
                        </span>
                      </div>
                      <div className={'align-self-center'}>
                        <span className={'text-muted fs-6 fw-bolder align-self-center me-3'}>
                          바람 세기
                        </span>
                        <span className={' fs-6 fw-bolder align-self-center '}>
                          <CountUp end={apiData?.wind?.speed} duration={1.2} decimals={2} /> m/s
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {userInfo?.accountType === 'sys_admin' && (
            <>
              <div className={'card row my-5 p-3'}>
                <List
                  sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontSize: '30px'}}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      component='div'
                      id='nested-list-subheader'
                      style={{fontSize: '30px', fontWeight: 'bold'}}
                    >
                      {userInfo?.accountTypeName} 메뉴
                    </ListSubheader>
                  }
                >
                  {/* 수용가 */}
                  <ListItemButton onClick={customerHandleClick}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='수용가' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/register')
                        }}
                      >
                        <ListItemIcon>
                          <RegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 등록' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/allocation')
                        }}
                      >
                        <ListItemIcon>
                          <CheckListIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 검수조회' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/info')
                        }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 정보관리' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/activity')
                        }}
                      >
                        <ListItemIcon>
                          <MonitoringIcon />
                        </ListItemIcon>
                        <ListItemText primary='건강 모니터링' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/check-report')
                        }}
                      >
                        <ListItemIcon>
                          <ReportIcon />
                        </ListItemIcon>
                        <ListItemText primary='검침 리포트' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/customer-status')
                        }}
                      >
                        <ListItemIcon>
                          <SituationIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 현황' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/minimum-flow')
                        }}
                      >
                        <ListItemIcon>
                          <MinimumFlowIcon />
                        </ListItemIcon>
                        <ListItemText primary='최소유량 조회' />
                      </ListItemButton>
                      {/*<ListItemButton*/}
                      {/*  sx={{pl: 4}}*/}
                      {/*  onClick={() => {*/}
                      {/*    navigate('/customer/block')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <ListItemIcon>*/}
                      {/*    <StarBorder />*/}
                      {/*  </ListItemIcon>*/}
                      {/*  <ListItemText primary='블록 별 사용량 조회' />*/}
                      {/*</ListItemButton>*/}
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/checklist')
                        }}
                      >
                        <ListItemIcon>
                          <CheckReportIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 검침조회' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/customer-leakage')
                        }}
                      >
                        <ListItemIcon>
                          <LeakCustomer color={'error'} />
                        </ListItemIcon>
                        <ListItemText primary='누수 감지조회' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 단말기 */}
                  <ListItemButton onClick={deviceHandleClick}>
                    <ListItemIcon>
                      <DeviceIcon />
                    </ListItemIcon>
                    <ListItemText primary='단말기' />
                    {deviceOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!deviceOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/register')
                        }}
                      >
                        <ListItemIcon>
                          <RegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 등록' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/allocation')
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 할당/검수 조회' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/info')
                        }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 정보관리' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/images')
                        }}
                      >
                        <ListItemIcon>
                          <ImageSearchIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 수신영상 관리' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 업무 */}
                  <ListItemButton onClick={taskHandleClick}>
                    <ListItemIcon>
                      <AddTaskIcon />
                    </ListItemIcon>
                    <ListItemText primary='업무' />
                    {deviceOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!taskOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/task/as')
                        }}
                      >
                        <ListItemIcon>
                          <HandymanIcon />
                        </ListItemIcon>
                        <ListItemText primary='AS 관리' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/task/recovery')
                        }}
                      >
                        <ListItemIcon>
                          <KeyboardReturnIcon />
                        </ListItemIcon>
                        <ListItemText primary='회수 조회' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 계정 */}
                  <ListItemButton onClick={accountHandleClick}>
                    <ListItemIcon>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary='계정' />
                    {accountOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!accountOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/account/group')
                        }}
                      >
                        <ListItemIcon>
                          <ClientIcon />
                        </ListItemIcon>
                        <ListItemText primary='사용자 계정관리' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 시스템 */}
                  <ListItemButton onClick={systemHandleClick}>
                    <ListItemIcon>
                      <SystemIcon />
                    </ListItemIcon>
                    <ListItemText primary='시스템' />
                    {systemOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!systemOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/system/code')
                        }}
                      >
                        <ListItemIcon>
                          <CodeIcon />
                        </ListItemIcon>
                        <ListItemText primary='시스템 코드관리' />
                      </ListItemButton>
                      {/*<ListItemButton*/}
                      {/*  sx={{pl: 4}}*/}
                      {/*  onClick={() => {*/}
                      {/*    navigate('/system/log')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <ListItemIcon>*/}
                      {/*    <LogIcon />*/}
                      {/*  </ListItemIcon>*/}
                      {/*  <ListItemText primary='시스템 로그관리' />*/}
                      {/*</ListItemButton>*/}
                    </List>
                  </Collapse>
                  {/* 설정 */}
                  <ListItemButton onClick={settingHandleClick}>
                    <ListItemIcon>
                      <SettingIcon />
                    </ListItemIcon>
                    <ListItemText primary='설정' />
                    {systemOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!settingOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/settings/setup')
                        }}
                      >
                        <ListItemIcon>
                          <OfficeSettingIcon />
                        </ListItemIcon>
                        <ListItemText primary='사업소 기초설정' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={installOpenClick}>
                    <ListItemIcon>
                      <InstallPage />
                    </ListItemIcon>
                    <ListItemText primary='설치 관리' />
                    {installOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!installOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/install/connect')
                        }}
                      >
                        <ListItemIcon>
                          <InstallIcon />
                        </ListItemIcon>
                        <ListItemText primary='설치' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/install/status')
                        }}
                      >
                        <ListItemIcon>
                          <InstallIcon color={'error'} />
                        </ListItemIcon>
                        <ListItemText primary='설치현황' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/install/as')
                        }}
                      >
                        <ListItemIcon>
                          <AsIcon />
                        </ListItemIcon>
                        <ListItemText primary='A/S' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </div>
            </>
          )}
          {userInfo?.accountType === 'sys_user' && (
            <>
              <div className={'card row my-5 p-3'}>
                <List
                  sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontSize: '30px'}}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      component='div'
                      id='nested-list-subheader'
                      style={{fontSize: '30px', fontWeight: 'bold'}}
                    >
                      {userInfo?.accountTypeName} 메뉴
                    </ListSubheader>
                  }
                >
                  {/* 수용가 */}
                  <ListItemButton onClick={customerHandleClick}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='수용가' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/register')
                        }}
                      >
                        <ListItemIcon>
                          <RegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 등록' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/allocation')
                        }}
                      >
                        <ListItemIcon>
                          <CheckListIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 검수조회' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/info')
                        }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 정보관리' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/activity')
                        }}
                      >
                        <ListItemIcon>
                          <MonitoringIcon />
                        </ListItemIcon>
                        <ListItemText primary='건강 모니터링' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/customer-status')
                        }}
                      >
                        <ListItemIcon>
                          <ReportIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 현황' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/minimum-flow')
                        }}
                      >
                        <ListItemIcon>
                          <MinimumFlowIcon />
                        </ListItemIcon>
                        <ListItemText primary='최소유량 조회' />
                      </ListItemButton>
                      {/*<ListItemButton*/}
                      {/*    sx={{pl: 4}}*/}
                      {/*    onClick={() => {*/}
                      {/*      navigate('/customer/block')*/}
                      {/*    }}*/}
                      {/*>*/}
                      {/*  <ListItemIcon>*/}
                      {/*    <StarBorder />*/}
                      {/*  </ListItemIcon>*/}
                      {/*  <ListItemText primary='블록 별 사용량 조회' />*/}
                      {/*</ListItemButton>*/}
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/customer-leakage')
                        }}
                      >
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary='누수 감지조회' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 단말기 */}
                  <ListItemButton onClick={deviceHandleClick}>
                    <ListItemIcon>
                      <DeviceIcon />
                    </ListItemIcon>
                    <ListItemText primary='단말기' />
                    {deviceOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!deviceOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/register')
                        }}
                      >
                        <ListItemIcon>
                          <RegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 등록' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/allocation')
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 할당/검수 조회' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/info')
                        }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 정보관리' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/images')
                        }}
                      >
                        <ListItemIcon>
                          <ImageSearchIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 수신영상 관리' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 업무 */}
                  <ListItemButton onClick={taskHandleClick}>
                    <ListItemIcon>
                      <AddTaskIcon />
                    </ListItemIcon>
                    <ListItemText primary='업무' />
                    {deviceOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!taskOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/task/as')
                        }}
                      >
                        <ListItemIcon>
                          <HandymanIcon />
                        </ListItemIcon>
                        <ListItemText primary='AS 관리' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/task/recovery')
                        }}
                      >
                        <ListItemIcon>
                          <KeyboardReturnIcon />
                        </ListItemIcon>
                        <ListItemText primary='회수 조회' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 계정 */}
                  <ListItemButton onClick={accountHandleClick}>
                    <ListItemIcon>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText primary='계정' />
                    {accountOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!accountOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/account/group')
                        }}
                      >
                        <ListItemIcon>
                          <ClientIcon />
                        </ListItemIcon>
                        <ListItemText primary='사용자 계정관리' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 시스템 */}
                  <ListItemButton onClick={systemHandleClick}>
                    <ListItemIcon>
                      <SystemIcon />
                    </ListItemIcon>
                    <ListItemText primary='시스템' />
                    {systemOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!systemOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/system/code')
                        }}
                      >
                        <ListItemIcon>
                          <CodeIcon />
                        </ListItemIcon>
                        <ListItemText primary='시스템 코드관리' />
                      </ListItemButton>
                      {/*<ListItemButton*/}
                      {/*  sx={{pl: 4}}*/}
                      {/*  onClick={() => {*/}
                      {/*    navigate('/system/log')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <ListItemIcon>*/}
                      {/*    <LogIcon />*/}
                      {/*  </ListItemIcon>*/}
                      {/*  <ListItemText primary='시스템 로그관리' />*/}
                      {/*</ListItemButton>*/}
                    </List>
                  </Collapse>
                  {/* 설정 */}
                  <ListItemButton onClick={settingHandleClick}>
                    <ListItemIcon>
                      <SettingIcon />
                    </ListItemIcon>
                    <ListItemText primary='설정' />
                    {systemOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!settingOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/settings/setup')
                        }}
                      >
                        <ListItemIcon>
                          <OfficeSettingIcon />
                        </ListItemIcon>
                        <ListItemText primary='사업소 기초설정' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </div>
            </>
          )}
          {userInfo?.accountType === 'inspector' && (
            <>
              <div className={'card row my-5 p-3'}>
                <List
                  sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontSize: '30px'}}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      component='div'
                      id='nested-list-subheader'
                      style={{fontSize: '30px', fontWeight: 'bold'}}
                    >
                      {userInfo?.accountTypeName} 메뉴
                    </ListSubheader>
                  }
                >
                  {/* 단말기 */}
                  <ListItemButton onClick={deviceHandleClick}>
                    <ListItemIcon>
                      <DeviceIcon />
                    </ListItemIcon>
                    <ListItemText primary='단말기' />
                    {deviceOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!deviceOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/register')
                        }}
                      >
                        <ListItemIcon>
                          <RegistrationIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 등록' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/allocation')
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 할당/검수 조회' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/info')
                        }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 정보관리' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </div>
            </>
          )}
          {userInfo?.accountType === 'gov_admin' && userInfo?.userId !== 'gov_uiwang' && (
            <>
              <div className={'card row my-5 p-3'}>
                <List
                  sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontSize: '30px'}}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      component='div'
                      id='nested-list-subheader'
                      style={{fontSize: '30px', fontWeight: 'bold'}}
                    >
                      {userInfo?.accountTypeName} 메뉴
                    </ListSubheader>
                  }
                >
                  <ListItemButton onClick={customerHandleClick}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='수용가' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/customer-status')
                        }}
                      >
                        <ListItemIcon>
                          <ReportIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 현황' />
                      </ListItemButton>
                      {/*<ListItemButton*/}
                      {/*  sx={{pl: 4}}*/}
                      {/*  onClick={() => {*/}
                      {/*    navigate('/customer/customer-leakage')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <ListItemIcon>*/}
                      {/*    <LeakCustomer color={'error'} />*/}
                      {/*  </ListItemIcon>*/}
                      {/*  <ListItemText primary='누수의심 조회' />*/}
                      {/*</ListItemButton>*/}
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/activity')
                        }}
                      >
                        <ListItemIcon>
                          <MonitoringIcon />
                        </ListItemIcon>
                        <ListItemText primary='건강모니터링' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/check-report')
                        }}
                      >
                        <ListItemIcon>
                          <CheckReportIcon />
                        </ListItemIcon>
                        <ListItemText primary='검침 리포트' />
                      </ListItemButton>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/minimum-flow')
                        }}
                      >
                        <ListItemIcon>
                          <MinimumFlowIcon />
                        </ListItemIcon>
                        <ListItemText primary='최소유량 조회' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 설정 */}
                  <ListItemButton onClick={settingHandleClick}>
                    <ListItemIcon>
                      <SettingIcon />
                    </ListItemIcon>
                    <ListItemText primary='설정' />
                    {systemOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!settingOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/settings/setup')
                        }}
                      >
                        <ListItemIcon>
                          <OfficeSettingIcon />
                        </ListItemIcon>
                        <ListItemText primary='사업소 기초설정' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 단말기 */}
                </List>
              </div>
            </>
          )}
          {userInfo?.accountType === 'gov_admin' && userInfo?.userId === 'gov_uiwang' && (
            <>
              <div className={'card row my-5 p-3'}>
                <List
                  sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontSize: '30px'}}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      component='div'
                      id='nested-list-subheader'
                      style={{fontSize: '30px', fontWeight: 'bold'}}
                    >
                      {userInfo?.accountTypeName} 메뉴
                    </ListSubheader>
                  }
                >
                  <ListItemButton onClick={customerHandleClick}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='수용가' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/customer/customer-status')
                        }}
                      >
                        <ListItemIcon>
                          <ReportIcon />
                        </ListItemIcon>
                        <ListItemText primary='수용가 현황' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* 단말기 */}
                </List>
              </div>
            </>
          )}
          {userInfo?.accountType === 'gov_user' && (
            <>
              <div className={'card row my-5 p-3'}>
                <List
                  sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontSize: '30px'}}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      component='div'
                      id='nested-list-subheader'
                      style={{fontSize: '30px', fontWeight: 'bold'}}
                    >
                      {userInfo?.accountTypeName} 메뉴
                    </ListSubheader>
                  }
                >
                  <ListItemButton onClick={deviceHandleClick}>
                    <ListItemIcon>
                      <DeviceIcon />
                    </ListItemIcon>
                    <ListItemText primary='단말기' />
                    {deviceOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={!deviceOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItemButton
                        sx={{pl: 4}}
                        onClick={() => {
                          navigate('/device/info')
                        }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='단말기 정보관리' />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

const MetervisionHome = () => {
  const isMobile = isMobileDevice()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'대시보드'}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {MetervisionHome}
