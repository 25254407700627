import {serverLocalName} from 'GLOBAL/envVariables'
import {useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {leakModelHandler} from 'utils/const/globalConst'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import './../../common/globalFontSize.css'

const CustomerHeader = (props: any) => {
  const {toast} = props
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const location = useLocation()
  return (
    <>
      {!isMobileDevice() && (
        <div className='card mb-5'>
          <div className='card-body pt-4 pb-0'>
            <div className='d-flex overflow-auto h-55px dynamic-font-size'>
              {userInfo?.accountType === 'sys_admin' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder d-flex flex-wrap gap-3'
                  style={{fontSize: 'calc(1rem + 0.2vw)', whiteSpace: 'nowrap'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/customer/register` && 'active')
                      }
                      to={`/customer/register`}
                    >
                      수용가 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/customer/allocation` && 'active')
                      }
                      to={`/customer/allocation`}
                    >
                      수용가 할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/info' && 'active')
                      }
                      to={`/customer/info`}
                    >
                      수용가 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/activity' && 'active')
                      }
                      to={`/customer/activity`}
                    >
                      건강 모니터링
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/checklist' && 'active')
                      }
                      to={`/customer/checklist`}
                    >
                      수용가 검침조회
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/check-report' && 'active')
                      }
                      to={`/customer/check-report`}
                    >
                      검침 리포트
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/minimum-flow' && 'active')
                      }
                      to={`/customer/minimum-flow`}
                    >
                      최소유량 조회
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/customer-status' && 'active')
                      }
                      to={`/customer/customer-status`}
                    >
                      수용가 현황
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/customer-leakage' && 'active')
                      }
                      to={`/customer/customer-leakage`}
                    >
                      누수의심 조회
                    </Link>
                  </li>

                  {leakModelHandler(serverLocalName, officeInfo.value) && (
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/customer/unused' && 'active')
                        }
                        to={`/customer/unused`}
                      >
                        장기미사용 조회
                      </Link>
                    </li>
                  )}
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/freeze-warning' && 'active')
                      }
                      to={`/customer/freeze-warning`}
                    >
                      동파위험 조회
                    </Link>
                  </li>
                  {/* <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/inspection' && 'active')
                      }
                      to={`/customer/customer-leak-temp`}
                    >
                      점검
                    </Link>
                  </li> */}
                </ul>
              )}
              {userInfo?.accountType === 'sys_user' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder d-flex flex-wrap gap-3'
                  style={{fontSize: 'calc(1rem + 0.2vw)', whiteSpace: 'nowrap'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/customer/register` && 'active')
                      }
                      to={`/customer/register`}
                    >
                      수용가 등록 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/customer/allocation` && 'active')
                      }
                      to={`/customer/allocation`}
                    >
                      수용가 할당 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/info' && 'active')
                      }
                      to={`/customer/info`}
                    >
                      수용가 정보 관리
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/activity' && 'active')
                      }
                      to={`/customer/activity`}
                    >
                      건강 모니터링
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/checklist' && 'active')
                      }
                      to={`/customer/checklist`}
                    >
                      수용가 검침조회
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/check-report' && 'active')
                      }
                      to={`/customer/check-report`}
                    >
                      검침 리포트
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/minimum-flow' && 'active')
                      }
                      to={`/customer/minimum-flow`}
                    >
                      최소유량 조회
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/customer-leakage' && 'active')
                      }
                      to={`/customer/customer-leakage`}
                    >
                      누수의심 조회
                    </Link>
                  </li>
                  {leakModelHandler(serverLocalName, officeInfo.value) === '2' && (
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/customer/unused' && 'active')
                        }
                        to={`/customer/unused`}
                      >
                        장기미사용 조회
                      </Link>
                    </li>
                  )}
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/freeze-warning' && 'active')
                      }
                      to={`/customer/freeze-warning`}
                    >
                      동파위험 조회
                    </Link>
                  </li>
                </ul>
              )}
              {userInfo?.accountType === 'gov_admin' && userInfo?.userId !== 'gov_uiwang' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder d-flex flex-wrap gap-3'
                  style={{fontSize: 'calc(1rem + 0.2vw)', whiteSpace: 'nowrap'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/customer/customer-status` && 'active')
                      }
                      to={`/customer/customer-status`}
                    >
                      수용가 현황
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === `/customer/activity` && 'active')
                      }
                      to={`/customer/activity`}
                    >
                      건강 모니터링
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/checklist' && 'active')
                      }
                      to={`/customer/checklist`}
                    >
                      수용가 검침조회
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/check-report' && 'active')
                      }
                      to={`/customer/check-report`}
                    >
                      검침 리포트
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/customer-leakage' && 'active')
                      }
                      to={`/customer/customer-leakage`}
                    >
                      누수의심 조회
                    </Link>
                  </li>
                  {leakModelHandler(serverLocalName, officeInfo.value) === '2' && (
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/customer/unused' && 'active')
                        }
                        to={`/customer/unused`}
                      >
                        장기미사용 조회
                      </Link>
                    </li>
                  )}
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/minimum-flow' && 'active')
                      }
                      to={`/customer/minimum-flow`}
                    >
                      최소유량 조회
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/customer-status' && 'active')
                      }
                      to={`/customer/customer-status`}
                    >
                      수용가 현황
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/freeze-warning' && 'active')
                      }
                      to={`/customer/freeze-warning`}
                    >
                      동파위험 조회
                    </Link>
                  </li>
                </ul>
              )}
              {userInfo?.accountType === 'customer_client' && (
                <ul
                  className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder d-flex flex-wrap gap-3'
                  style={{fontSize: 'calc(1rem + 0.2vw)', whiteSpace: 'nowrap'}}
                >
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/customer/client' && 'active')
                      }
                      to={`/customer/client`}
                    >
                      수용가
                    </Link>
                  </li>
                </ul>
              )}
              {userInfo?.accountType === 'gov_meterman' ||
                (userInfo?.accountType === 'gov_meterman_editable' && (
                  <ul
                    className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder d-flex flex-wrap gap-3'
                    style={{fontSize: 'calc(1rem + 0.2vw)', whiteSpace: 'nowrap'}}
                  >
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/customer/customer-status' && 'active')
                        }
                        to={`/customer/customer-status`}
                      >
                        수용가 현황
                      </Link>
                    </li>
                  </ul>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default CustomerHeader
