export const yMarker = require('./images/y_marker.png') //영아이티
export const dMarker = require('./images/d_marker.png') // 두리
export const cMarker = require('./images/c_marker.png') // 청호
export const kMarker = require('./images/k_marker.png') // 케이스마트

export const deviceError = require('./images/device_err.png')
export const deviceSuccess = require('./images/device_suc.png')

export const digitalMeterError = require('./images/digitalMeter_err.png')
export const digitalMeterSuccess = require('./images/digitalMeter_suc.png')

export const duriError = require('./images/duri_device_err.png')
export const duriSuccess = require('./images/duri_device_suc.png')

export const kSmartError = require('./images/ksmart_device_err.png')
export const kSmartSuccess = require('./images/ksmart_device_suc.png')

export const chungHoError = require('./images/chungHo_device_err.png')
export const chungHoSuccess = require('./images/chungHo_device_suc.png')

export const meterariumLogo = require('./images/meterarium_new_logo.png')
export const meterariumLogoGIF = require('./images/meterarium_new_logo.gif')

export const youngitWhiteLogo = require('./images/youngit-white.png')
export const youngitLogo = require('./images/youngit_logo_mini.png')

export const rotateImg = require('./images/rotateIcon.png')

export const defaultImage = require('./images/defaultImage.png')
export const efosSuccess = require('./images/efos_suc.png')
export const efosError = require('./images/efos_fail.png')

export const noImage = require('./images/noImage.png')

export const yitHome = require('./images/youngitHompage.png')
export const officeHome = require('./images/officeHomePage.png')
export const hycheckHome = require('./images/hycheckHomepage.png')

export const yitBanner1 = require('./images/yit-banner1.png')
export const yitBanner2 = require('./images/yit-banner2.png')
export const yitBanner3 = require('./images/yit-banner3.png')
export const yitBanner4 = require('./images/yit-banner4.png')
export const yitBanner5 = require('./images/yit-banner5.png')
export const noDataImg = require('./images/noData.jpg')
export const backgrondImg = require('./images/background.png')

export const accessImg = require('./images/access.jpg')
export const arrowLottie = require('./images/arrow-lottie.json')

export const searchWaitingLottie = require('./images/search-waiting.json')

export const selectSuccessEfos = (cooperIdx: number) => {
  switch (cooperIdx) {
    case 1:
      return efosSuccess
    case 3:
    case 4:
    case 5:
      return digitalMeterSuccess
    default:
      return efosSuccess
  }
}
export const selectFailEFos = (cooperIdx: number) => {
  switch (cooperIdx) {
    case 1:
      return efosError
    case 3:
    case 4:
    case 5:
      return digitalMeterError
    default:
      return efosError
  }
}

export const selectSuccessImg = (cooperIdx: number) => {
  switch (cooperIdx) {
    case 1:
      return deviceSuccess
    case 3:
      return duriSuccess
    case 4:
      return kSmartSuccess
    case 5:
      return chungHoSuccess
    default:
      return deviceSuccess
  }
}
export const selectFailImg = (cooperIdx: number) => {
  switch (cooperIdx) {
    case 1:
      return deviceError
    case 3:
      return duriError
    case 4:
      return kSmartError
    case 5:
      return chungHoError
    default:
      return deviceError
  }
}

export const selectMarker = (cooperIdx: number) => {
  switch (cooperIdx) {
    case 1:
      return yMarker
    case 3:
      return dMarker
    case 4:
      return kMarker
    case 5:
      return cMarker
    default:
      return yMarker
  }
}
