import {meterariumLogo, selectMarker} from 'assets/imageDeclear'
import {envImagePath} from 'GLOBAL/envVariables'
import {customerInfoFilter} from 'pages/customer/dataConfig/declearFilters'
import {useLayoutEffect, useRef, useState} from 'react'
import ReactDOMServer from 'react-dom/server'
import {useSelector} from 'react-redux'
import {getCustomerList} from 'utils/apiCalling/api'

declare global {
  interface Window {
    kakao: any
  }
}

const MapContainer = (props: any) => {
  const {cluster, cityName} = props
  console.log(cityName)
  const [loading, setLoading] = useState(true)
  const [apiLoading, setApiLoading] = useState(true)
  const userInfo = useSelector((state: any) => state.userInfo)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const cooperInfo = useSelector((state: any) => state.cooperInfo)
  const mapRef = useRef<any>(null)
  const filter = customerInfoFilter(1)

  const InfoWindowContent = (content: any) => {
    const {data} = content
    return (
      <div className={'w-100'}>
        <div
          className='px-1 fw-bold fs-4 my-3 border-gray-300 rounded-2 bg-dark text-white text-center'
          style={{width: '350px', position: 'absolute'}}
        >
          <div className='w-100 my-2 d-flex justify-content-between'>
            <div>
              <img src={meterariumLogo} alt='meterarium-logo' width={150} />
            </div>
          </div>
          {data?.filePath == null ? null : (
            <img
              className={'mb-2 border-1 border-gray-300 align-self-center'}
              src={envImagePath + data?.filePath}
              width={'320px'}
              alt={'meter-image'}
            />
          )}
          <div className={'d-flex'}>
            <span>검침값 : </span>
            <span className={'fs-3 ms-2'}>
              <u>{data?.checkValue === null ? '인식 중 입니다.' : data?.checkValue}</u>
            </span>
          </div>
          <div className={'d-flex'}>
            <span>
              수용가 번호 : <strong className={'text-primary ms-2'}>{data?.customerNumber}</strong>{' '}
            </span>
          </div>
          <div className={'d-flex'}>
            <span>
              수용가 이름 : <strong className={'text-primary ms-2'}>{data?.customerName}</strong>{' '}
            </span>
          </div>
          <div className={'d-flex mb-3 '} style={{width: '320px'}}>
            <span>수용가 주소 : </span>
            <span className={' ms-2'}>
              {data?.addressRoad} {data?.addressDetail}
            </span>
          </div>
        </div>
      </div>
    )
  }

  useLayoutEffect(() => {
    let container = document?.getElementById('map') //지도를 담을 영역의 DOM 레퍼런스
    let placeNameFirstPart = officeInfo?.label?.slice(0, 2)
    if (placeNameFirstPart === '구미') {
      placeNameFirstPart = '경북 구미시'
    }
    let options = {
      center: new window.kakao.maps.LatLng(36.5194, 127.7256), //지도의 중심좌표.
      level: 9, //지도의 레벨(확대, 축소 정도)
      mapTypeId: window.kakao.maps.MapTypeId.HYBRID, //지도종류
    }
    let map = new window.kakao.maps.Map(container, options) //지도 생성 및 객체 리턴
    // 지역 이름을 기준으로 좌표 설정
    let geocoder = new kakao.maps.services.Geocoder() // Geocoder 객체 생성
    geocoder.addressSearch(placeNameFirstPart, function (result, status) {
      if (status === kakao.maps.services.Status.OK) {
        console.log('map', result)
        let centerPosition = new window.kakao.maps.LatLng(result[0].y, result[0].x)
        map.setCenter(centerPosition)
      }
    })

    const clusterer = new kakao.maps.MarkerClusterer({
      map: map,
      averageCenter: true,
      minLevel: 3,
      gridSize: 70,
      minClusterSize: 20,
    })

    // 비동기적으로 마커를 추가하는 함수
    const addMarkersInChunks = (markers: any, chunkSize = 30) => {
      let index = 0

      const addNextChunk = () => {
        if (index < markers.length) {
          const nextChunk = markers.slice(index, index + chunkSize)
          nextChunk.forEach((markerData: any) => {
            if (markerData.latitude && markerData.longitude) {
              let marker = new kakao.maps.Marker({
                position: new window.kakao.maps.LatLng(markerData.latitude, markerData.longitude),
                title: markerData.customerName,
                image: new kakao.maps.MarkerImage(
                  selectMarker(markerData.cooperIdx),

                  new kakao.maps.Size(43, 60)
                ),
              })
              clusterer.addMarker(marker)
              // 클릭 시 API 호출
              kakao.maps.event.addListener(marker, 'click', async function () {
                try {
                  getCustomerList(
                    officeInfo,
                    cooperInfo,
                    {...filter, searchData: markerData.customerNumber},
                    (res: any, count: any) => {
                      if (res && res.length > 0) {
                        // 받아온 데이터를 기반으로 커스텀 오버레이 생성
                        const iwContent = ReactDOMServer.renderToString(
                          <InfoWindowContent data={res[0]} />
                        )

                        let customOverlay = new kakao.maps.CustomOverlay({
                          position: new window.kakao.maps.LatLng(
                            markerData.latitude,
                            markerData.longitude
                          ),
                          content: iwContent,
                        })

                        // 지도에 오버레이 추가
                        customOverlay.setMap(map)

                        // 마우스를 마커 밖으로 이동할 때 오버레이 제거
                        kakao.maps.event.addListener(marker, 'mouseout', function () {
                          customOverlay.setMap(null)
                        })
                      }
                    },
                    (error: any) => {
                      console.error('Error loading customer list', error)
                    },
                    () => {
                      setApiLoading(false)
                    }
                  )
                } catch (error) {
                  console.error('Failed to load marker information', error)
                }
              })
            }
          })

          index += chunkSize
          // 다음 작업을 비동기로 실행하여 브라우저가 멈추는 것을 방지
          setTimeout(addNextChunk, 500)
        }
      }

      addNextChunk()
    }

    // 초기 로딩 및 데이터 세팅
    setLoading(true)

    cluster.forEach((clusterData: any) => {
      if (clusterData.points && Array.isArray(clusterData.points)) {
        addMarkersInChunks(clusterData.points, 30) // 마커를 비동기적으로 추가
      }
    })

    setLoading(false)

    kakao.maps.event.addListener(clusterer, 'clusterclick', function (cluster: any) {
      // 현재 지도 레벨에서 1레벨 확대한 레벨
      let level = map.getLevel() - 1
      // 지도를 클릭된 클러스터의 마커의 위치를 기준으로 확대합니다
      map.setLevel(level, {anchor: cluster.getCenter()})
    })
    kakao.maps.event.addListener(map, 'tilesloaded', function () {
      setLoading(false)
    })

    kakao.maps.event.addListener(map, 'zoom_changed', function () {
      // 줌 레벨이 변경되면 스피너를 표시
      setLoading(true)
    })

    // idle 이벤트 리스너
    kakao.maps.event.addListener(map, 'idle', function () {
      setLoading(false)
    })
  }, [cluster])

  return (
    <>
      <div
        id='map'
        style={{width: '100%', height: '650px'}}
        ref={mapRef}
        className={'border-1 border-white rounded-2'}
      >
        {loading && (
          <div
            className='row align-items-center text-center gap-8 align-self-center justify-content-center'
            style={{marginTop: '80px'}}
          >
            <div
              className='spinner-border '
              style={{width: '7rem', height: '7rem', color: 'white', borderWidth: '0.5rem'}}
              role='status'
            ></div>
            <span className='fs-3 fw-bold text-white'>지도를 불러오는 중 입니다. </span>
          </div>
        )}
      </div>
    </>
  )
}

export default MapContainer
