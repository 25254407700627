/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, {ApexOptions} from 'apexcharts'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, {useEffect, useRef} from 'react'
import {getCSS, getCSSVariableValue, isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  checkList?: any
}

const TemperatureHistoryChart: React.FC<Props> = ({className, checkList}) => {
  const filteredCheckList = checkList.filter((el: any) => el.recordedTemperature !== 99)

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, filteredCheckList))
    if (chart) {
      chart.render()
    }

    return chart
  }
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, checkList])

  return (
    <div
      className={'w-100'}
      ref={chartRef}
      id='kt_charts_widget_4_chart'
      style={{height: '100%'}}
    ></div>
  )
}

export {TemperatureHistoryChart}

function getChartOptions(height: number, checkList: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const borderColor = getCSSVariableValue('--kt-gray-400')

  const baseColor = getCSSVariableValue('--kt-success')
  const baseLightColor = getCSSVariableValue('--kt-success-light')
  const secondaryColor = getCSSVariableValue('--kt-warning')
  const secondaryLightColor = getCSSVariableValue('--kt-warning-light')

  const fontSize = isMobileDevice() ? '12px' : '18px'
  return {
    series: [
      {
        name: `온도`,
        data: _.map(checkList, (el) => el.recordedTemperature), // 온도 데이터만 사용
      },
    ],
    chart: {
      fontFamily: 'inter',
      id: 'area-datetime',
      type: 'area',
      height: height - 10,
      width: '100%',
      offsetY: 20, // 차트를 아래로 내려 툴바가 잘리지 않도록 함
      toolbar: {
        show: true,
        offsetY: 10, // 툴바의 Y 위치를 추가로 조정하여 잘리지 않게 만듦
        tools: {
          download: false,
          selection: false, // 범위 선택 툴바 비활성화
          zoom: true, // 줌 툴바 표시
          zoomin: true, // 확대 툴바 표시
          zoomout: true, // 축소 툴바 표시
          pan: false, // 팬 툴바 비활성화
          reset: false, // 홈 버튼 비활성화 (기본값인 리셋 버튼 제거)
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: '#fd0000',
          borderWidth: 4,
          strokeDashArray: 2,
        },
      ],
    },
    legend: {
      show: true,
      fontSize: fontSize,
      fontWeight: 600,
    },

    // 라벨 색
    dataLabels: {
      enabled: true,
      style: {
        fontSize: fontSize,
        fontWeight: 500,
      },
      formatter(val: string | number | number[], opts: any): string | number {
        if (opts.dataPointIndex % 7 === 0) {
          return Number(val).toLocaleString('ko-KR') + '℃'
        } else {
          return ''
        }
      },
    },
    // 아래 영역 색
    fill: {
      colors: [`#21af02`],
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: _.map(checkList, (el) => {
        return dayjs(el.recordedTemperatureAt).format('MM-DD') // 날짜 형식을 'MM-DD'로 설정
      }),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: isMobileDevice() ? '10px' : '14px',
          fontWeight: 500,
        },
        rotate: 0, // 레이블을 수평으로 정렬
        maxHeight: 50, // 레이블의 최대 높이 지정
        showDuplicates: false,
      },
      tickAmount: Math.floor(checkList.length / 4), // X축 레이블의 표시 간격 줄임
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: -20,
      max: 30,
      labels: {
        style: {
          colors: labelColor,
          fontSize: fontSize,
        },
        offsetX: -10,
        formatter(val: number): string {
          return Number(val).toFixed(1) + '℃'
        },
      },
      tickAmount: undefined, // tickAmount 대신 interval 사용
      forceNiceScale: true, // 눈금 간격을 깔끔하게 유지
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      custom: function ({series, seriesIndex, dataPointIndex}) {
        const xValue = dayjs(checkList[dataPointIndex].recordedTemperatureAt).format(
          'MM월 DD일  HH:mm'
        )
        const yValue = series[seriesIndex][dataPointIndex]
        return `<div class="tooltip-card" style="padding: 8px; color: #ffffff; background: #21af02; border-radius: 8px;">
                  <h2 style="margin: 0; font-size: 14px;">${xValue}</h2>
                  <h2 style="margin: 0; font-size: 14px;">온도: <u>${yValue} ℃</u></h2>
                </div>`
      },
    },
    colors: ['#068029', '#15dc4e'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 8,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 3,
    },
  }
}
