/* eslint-disable react/jsx-no-target-blank */
import {serverLocalName} from 'GLOBAL/envVariables'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {leakModelHandler} from 'utils/const/globalConst'
import {useAuth} from '../../../../_component/modules/auth'
import {isMobileDevice} from '../../../assets/ts/_utils'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import './asside.css'

export function AsideMenuMain() {
  const intl = useIntl()
  const isMobile = isMobileDevice()
  const {currentUser} = useAuth()
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const {label, value} = officeInfo
  const aSideMenuHandler = (type: any) => {
    switch (type) {
      case 'sys_admin':
        return (
          <>
            <AsideMenuItemWithSub
              to='/customer/*'
              title='수용가'
              icon='/media/icons/duotune/general/gen001.svg'
            >
              <AsideMenuItem to={`/customer/register`} title='수용가 등록 관리' hasBullet={true} />
              <AsideMenuItem
                to={`/customer/allocation`}
                title='수용가 할당 관리'
                hasBullet={true}
              />
              <AsideMenuItem to={`/customer/info`} title='수용가 정보관리 ' hasBullet={true} />
              <AsideMenuItem to={`/customer/activity`} title='건강 모니터링' hasBullet={true} />
              <AsideMenuItem to={`/customer/checklist`} title='수용가 검침조회' hasBullet={true} />
              <AsideMenuItem to={`/customer/check-report`} title='검침 리포트' hasBullet={true} />
              <AsideMenuItem to={`/customer/minimum-flow`} title='최소유량 조회' hasBullet={true} />
              {/*<AsideMenuItem*/}
              {/*  to={`/customer/block`}*/}
              {/*  title='블록 별 사용량 조회'*/}
              {/*  hasBullet={true}*/}
              {/*/>*/}
              <AsideMenuItem
                to={`/customer/customer-status`}
                title='수용가 현황'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`/customer/customer-leakage`}
                title='누수의심 조회'
                hasBullet={true}
              />

              {leakModelHandler(serverLocalName, officeInfo.value) === '2' && (
                <AsideMenuItem to={`/customer/unused`} title='장기미사용 조회' hasBullet={true} />
              )}
              <AsideMenuItem
                to={`/customer/freeze-warning`}
                title='동파위험 조회'
                hasBullet={true}
              />

              {/* <AsideMenuItem to={`/customer/inspection`} title='점검' hasBullet={true} /> */}
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/device/*'
              title='단말기'
              icon='/media/icons/duotune/electronics/elc003.svg'
            >
              <AsideMenuItem to={`/device/register`} title='단말기 등록 관리' hasBullet={true} />
              <AsideMenuItem
                to={`/device/allocation`}
                title='단말기 검수/할당 관리'
                hasBullet={true}
              />
              <AsideMenuItem to={`/device/info`} title='단말기 정보 관리' hasBullet={true} />
              <AsideMenuItem to={`/device/images`} title='단말기 수신영상 관리' hasBullet={true} />
              <AsideMenuItem to={`/device/history`} title='단말기 HISTORY' hasBullet={true} />
              {/* 단말기 펌웨어 */}
              {/*<AsideMenuItem to='/pages/device/firmware' title='단말기 펌웨어 관리' hasBullet={true} />*/}
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/task/*'
              title='업무'
              icon='/media/icons/duotune/files/fil002.svg'
            >
              <AsideMenuItem to={`/task/as`} title='AS 관리' hasBullet={true} />
              <AsideMenuItem to={`/task/recovery`} title='회수 조회' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/account/*'
              title='계정관리'
              icon='/media/icons/duotune/communication/com006.svg'
            >
              <AsideMenuItem to={`/account/group`} title='사용자 계정관리' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/system/*'
              title='시스템'
              icon='/media/icons/duotune/coding/cod001.svg'
            >
              <AsideMenuItem to='/system/code' title='시스템 코드관리' hasBullet={true} />
              {/*<AsideMenuItem to='/system/log' title='시스템 로그관리' hasBullet={true} />*/}
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/match/*'
              title='매칭시스템'
              icon='/media/icons/duotune/coding/cod001.svg'
            >
              <AsideMenuItem to='/match/fota' title='FOTA연결' hasBullet={true} />
              {/*<AsideMenuItem to='/system/log' title='시스템 로그관리' hasBullet={true} />*/}
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/settings/*'
              title='설정'
              icon='/media/icons/duotune/general/gen003.svg'
            >
              <AsideMenuItem to={`/settings/setup`} title='사업소 기초설정' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/install/*'
              title='설치자 관리'
              icon='/media/icons/duotune/coding/cod009.svg'
            >
              <AsideMenuItem to='/install/connect' title='설치' hasBullet={true} />
              <AsideMenuItem to='/install/status' title='설치현황' hasBullet={true} />
              <AsideMenuItem to='/install/as' title='A/S' hasBullet={true} />
            </AsideMenuItemWithSub>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
              </div>
            </div>
            <div className='menu-item'>
              <div className={'border-top border-2 border-white'} />
            </div>
            <AsideMenuItemWithSub
              to='/charge/*'
              title='요금연계'
              icon='/media/icons/duotune/finance/fin010.svg'
            >
              <AsideMenuItem to='/charge/select' title='요금연계' hasBullet={true} />
            </AsideMenuItemWithSub>
          </>
        )
      case 'sys_user':
        return (
          <>
            <AsideMenuItemWithSub
              to='/customer/*'
              title='수용가'
              icon='/media/icons/duotune/general/gen001.svg'
            >
              <AsideMenuItem to={`/customer/register`} title='수용가 등록 관리' hasBullet={true} />
              <AsideMenuItem
                to={`/customer/allocation`}
                title='수용가 할당 관리'
                hasBullet={true}
              />
              <AsideMenuItem to={`/customer/info`} title='수용가 정보관리 ' hasBullet={true} />
              <AsideMenuItem to={`/customer/activity`} title='건강 모니터링 ' hasBullet={true} />
              <AsideMenuItem to={`/customer/checklist`} title='수용가 검침조회' hasBullet={true} />
              <AsideMenuItem to={`/customer/check-report`} title='검침 리포트' hasBullet={true} />
              <AsideMenuItem to={`/customer/minimum-flow`} title='최소유량 조회' hasBullet={true} />
              <AsideMenuItem
                to={`/customer/customer-status`}
                title='수용가 현황'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`/customer/customer-leakage`}
                title='누수의심 조회'
                hasBullet={true}
              />
              {leakModelHandler(serverLocalName, officeInfo.value) === '2' && (
                <AsideMenuItem to={`/customer/unused`} title='장기미사용 조회' hasBullet={true} />
              )}
              <AsideMenuItem
                to={`/customer/freeze-warning`}
                title='동파위험 조회'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/device/*'
              title='단말기'
              icon='/media/icons/duotune/electronics/elc003.svg'
            >
              <AsideMenuItem to={`/device/register`} title='단말기 등록 관리' hasBullet={true} />
              <AsideMenuItem
                to={`/device/allocation`}
                title='단말기 검수/할당 관리'
                hasBullet={true}
              />
              <AsideMenuItem to={`/device/info`} title='단말기 정보 관리' hasBullet={true} />
              <AsideMenuItem to={`/device/images`} title='단말기 수신영상 관리' hasBullet={true} />
              <AsideMenuItem to={`/device/history`} title='단말기 HISTORY' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/task/*'
              title='업무'
              icon='/media/icons/duotune/files/fil002.svg'
            >
              <AsideMenuItem to={`/task/as`} title='AS 관리' hasBullet={true} />
              <AsideMenuItem to={`/task/recovery`} title='회수 조회' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/account/*'
              title='계정관리'
              icon='/media/icons/duotune/communication/com006.svg'
            >
              <AsideMenuItem to={`/account/group`} title='사용자 계정관리' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/system/*'
              title='시스템'
              icon='/media/icons/duotune/coding/cod001.svg'
            >
              <AsideMenuItem to='/system/code' title='시스템 코드관리' hasBullet={true} />
              {/*<AsideMenuItem to='/system/log' title='시스템 로그관리' hasBullet={true} />*/}
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/settings/*'
              title='설정'
              icon='/media/icons/duotune/general/gen003.svg'
            >
              <AsideMenuItem to={`/settings/setup`} title='사업소 기초설정' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to='/install/*'
              title='설치자 관리'
              icon='/media/icons/duotune/coding/cod009.svg'
            >
              <AsideMenuItem to='/install/connect' title='설치' hasBullet={true} />
              <AsideMenuItem to='/install/status' title='설치현황' hasBullet={true} />
              <AsideMenuItem to='/install/as' title='A/S' hasBullet={true} />
            </AsideMenuItemWithSub>

            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
              </div>
            </div>
          </>
        )
      case 'gov_admin':
        return (
          <>
            <AsideMenuItemWithSub
              to='/customer/*'
              title='수용가'
              icon='/media/icons/duotune/general/gen001.svg'
            >
              <AsideMenuItem
                to={`/customer/customer-status`}
                title='수용가 현황'
                hasBullet={true}
              />
              <AsideMenuItem to={`/customer/activity`} title='건강 모니터링' hasBullet={true} />
              <AsideMenuItem to={`/customer/checklist`} title='수용가 검침조회' hasBullet={true} />
              <AsideMenuItem to={`/customer/check-report`} title='검침 리포트' hasBullet={true} />
              <AsideMenuItem
                to={`/customer/customer-leakage`}
                title='누수의심 조회'
                hasBullet={true}
              />
              {leakModelHandler(serverLocalName, officeInfo.value) === '2' && (
                <AsideMenuItem to={`/customer/unused`} title='장기미사용 조회' hasBullet={true} />
              )}
              <AsideMenuItem to={`/customer/minimum-flow`} title='최소유량 조회' hasBullet={true} />
              <AsideMenuItem
                to={`/customer/freeze-warning`}
                title='동파위험 조회'
                hasBullet={true}
              />
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
                </div>
              </div>
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
              to='/settings/*'
              title='설정'
              icon='/media/icons/duotune/general/gen003.svg'
            >
              <AsideMenuItem to={`/settings/setup`} title='사업소 기초설정' hasBullet={true} />
            </AsideMenuItemWithSub>

            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
              </div>
            </div>
          </>
        )
      case 'charge_user':
        return (
          <>
            <div className='menu-item'>
              <div className={'border-top border-2 border-white'} />
            </div>
            <AsideMenuItemWithSub
              to='/charge/*'
              title='요금연계'
              icon='/media/icons/duotune/finance/fin010.svg'
            >
              <AsideMenuItem to='/charge/select' title='요금연계' hasBullet={true} />
            </AsideMenuItemWithSub>
          </>
        )
      case 'installer':
        return (
          <>
            <AsideMenuItemWithSub
              to='/install/*'
              title='설치자 관리'
              icon='/media/icons/duotune/coding/cod009.svg'
            >
              <AsideMenuItem to='/install/connect' title='설치' hasBullet={true} />
              <AsideMenuItem to='/install/status' title='설치 완료' hasBullet={true} />
              <AsideMenuItem to='/install/as' title='A/S' hasBullet={true} />
              <AsideMenuItem to='/install/as/complete' title='A/S 완료' hasBullet={true} />
            </AsideMenuItemWithSub>
          </>
        )

      case 'inspector':
        return (
          <>
            <AsideMenuItemWithSub
              to='/device/*'
              title='단말기'
              icon='/media/icons/duotune/electronics/elc003.svg'
            >
              <AsideMenuItem to={`/device/register`} title='단말기 등록 관리' hasBullet={true} />
              <AsideMenuItem
                to={`/device/allocation`}
                title='단말기 검수/할당 관리'
                hasBullet={true}
              />
              <AsideMenuItem to={`/device/info`} title='단말기 정보 관리' hasBullet={true} />
              {/* 단말기 펌웨어 */}
              {/*<AsideMenuItem to='/pages/device/firmware' title='단말기 펌웨어 관리' hasBullet={true} />*/}
            </AsideMenuItemWithSub>
          </>
        )

      case 'gov_user':
        return (
          <>
            <AsideMenuItemWithSub
              to='/device/*'
              title='단말기'
              icon='/media/icons/duotune/electronics/elc003.svg'
            >
              <AsideMenuItem to={`/device/info`} title='단말기 정보 관리' hasBullet={true} />
            </AsideMenuItemWithSub>
          </>
        )

      case 'customer_client':
        return (
          <>
            <AsideMenuItem to={`/customer/client`} title='수용가' hasBullet={true} />
          </>
        )
      case 'gov_meterman':
      case 'gov_meterman_editable':
        return (
          <>
            <AsideMenuItemWithSub
              to='/customer/*'
              title='수용가'
              icon='/media/icons/duotune/general/gen001.svg'
            >
              <AsideMenuItem
                to={`/customer/customer-status`}
                title='수용가 현황'
                hasBullet={true}
              />

              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
                </div>
              </div>
            </AsideMenuItemWithSub>
          </>
        )

      default:
        return (
          <>
            <AsideMenuItemWithSub
              to='/customer/*'
              title='수용가'
              icon='/media/icons/duotune/general/gen001.svg'
            >
              <AsideMenuItem
                to={`/customer/customer-status`}
                title='수용가 현황'
                hasBullet={true}
              />

              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
                </div>
              </div>
            </AsideMenuItemWithSub>
          </>
        )
    }
  }

  return <>{officeInfo && <div>{aSideMenuHandler(userInfo?.accountType)}</div>}</>
}
