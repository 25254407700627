import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningIcon from '@mui/icons-material/Warning'
import {TreeItem, TreeView} from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {KTSVG} from '_metronic/helpers'
import {envBackHost} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import StyledTreeItem from 'pages/officeConfig/config/StyleTreeItem'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {toast, ToastContainer} from 'react-toastify'
import {serverCycleChange} from 'utils/apiCalling/api'
import {confirmAlert, customConfirm, resultAlert} from 'utils/CustomAlert/Alert'
import CustomerAddModal from '../modules/CustomerAddModal'
import TypeUpdateModal from '../modules/TypeUpdateModal'
import WorkTypeUpdateModal from '../modules/WorkTypeUpdateModal'
import './officeConfigSetup'

const OfficeConfigSetup = (props: any) => {
  const {officeList, flag, setFlag, getOfficeList} = props
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [selectedOfficeInfo, setSelectedOfficeInfo] = useState<any>(null)
  const [selectedType, setSelectedType] = useState<any>(null)
  const [selectedWorkType, setSelectedWorkType] = useState<any>(null)
  const [detailInfoFlag, setDetailInfoFlag] = useState<any>(false)
  const levelOffice1 = _.filter(officeList, {officeLevel: 1})
  const levelOffice2 = _.filter(officeList, {officeLevel: 2})
  const levelOffice3 = _.filter(officeList, {officeLevel: 3})
  const treeViewRef = useRef<any>(null)
  const searchOfficeList = _(officeList)
    .filter((el) => el.officeLevel !== 3)
    .map((el) => ({label: el.officeName, value: el.officeId}))
    .value()
  const [serverCycle, setServerCycle] = useState<number>(0)
  /** dynamic modal **/
  const [modalType, setModalType] = useState('')
  const basicDefault = {
    durability: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    durabilityExpired: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    batteryExpired: [10, 20, 30, 40, 50],
  }

  const [updateCustomerDetailInfo, setUpdateCustomerDetailInfo] = useState<any>(null)

  /** Tree **/
  const [expanded, setExpanded] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds)
  }

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? _.map(officeList, (el, i) => {
            return el.officeId
          })
        : []
    )
  }
  /** API **/
  const getRefreshOfficeList = () => {
    toast.promise(
      axiosWithAuth()
        .post(`${envBackHost}/office/refresh`)
        .then(async (res) => {
          await resultAlert(res.data, res.data.message)
          if (res.data.resultCode === 0) {
            getOfficeList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        }),
      {
        pending: '사업소 업데이트 중입니다.',
      },
      {
        position: 'bottom-left',
        closeButton: false,
        className: 'fs-3  fw-bold',
        style: {width: '400px'},
      }
    )
  }
  const typeOfInfoUpdate = async (el: any) => {
    console.log('target==========>', el.customerTypeName, el.officeId)
    const data = updateCustomerDetailInfo[el?.customerTypeName]
    // console.log(data)
    await axiosWithAuth()
      .put(`${envBackHost}/office/${el?.officeId}/customertype`, data)
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          getOfficeDetailInfo()
          setDetailInfoFlag(!detailInfoFlag)
          setFlag(!flag)
          return
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }
  const getOfficeDetailInfo = async () => {
    if (!selected[0]) {
      return null
    }
    axiosWithAuth()
      .get(`${envBackHost}/office/${selected[0]}`)
      .then((res) => {
        setSelectedOfficeInfo(res.data.res[0])
        setFlag(!flag)
      })
  }
  const deleteOffice = async () => {
    if (await confirmAlert('삭제', 'info')) {
      if (await customConfirm('삭제 후 복구는 불가능합니다.', 'warning', '동의 후 삭제')) {
        axiosWithAuth()
          .delete(`${envBackHost}/office/${selectedOfficeInfo?.officeId}`)
          .then(async (res) => {
            await resultAlert(res.data)
            if (res.data.resultCode === 0) {
              setFlag(!flag)
              await resetHandler()
              return
            }
          })
          .catch(async (e) => {
            await resultAlert({resultCode: e.response.status})
          })
      } else {
        return
      }
    } else {
      return
    }
  }
  const serverCycleUpdate = async (value: number) => {
    serverCycleChange(userInfo, selectedOfficeInfo, value, {
      success: (res) => {
        console.log(res)
      },
      fail: (e) => {
        console.log(e)
      },
    })
  }
  const resetHandler = async () => {
    setSelectedOfficeInfo(null)
    setSelected([])
  }

  useEffect(() => {
    setUpdateCustomerDetailInfo({})
    setServerCycle(0)
    if (selectedOfficeInfo?.officeCustomerTypeModel) {
      _.map(selectedOfficeInfo?.officeCustomerTypeModel, (el, i) => {
        return setUpdateCustomerDetailInfo((prevInfo: any) => {
          return {
            ...prevInfo,
            [el.customerTypeName]: {
              customerTypeName: el.customerTypeName,
              idx: el.idx,
              leakUseCycle: el.leakUseCycle,
              leakUseStandard: el.leakUseStandard,
              leakUseUnit: el.leakUseUnit,
              lowUseCycle: el.lowUseCycle,
              lowUseStandard: el.lowUseStandard,
              lowUseUnit: el.lowUseUnit,
            },
          }
        })
      })
    }
  }, [selectedOfficeInfo?.officeId])

  useEffect(() => {}, [officeList])

  useEffect(() => {
    getOfficeDetailInfo()
  }, [detailInfoFlag])
  useEffect(() => {
    setUpdateCustomerDetailInfo({})
    setSelectedOfficeInfo(null)
    getOfficeDetailInfo()
  }, [selected])

  return (
    <>
      {!isMobileDevice() && (
        <div className={'card card-body py-4 flex-grow-1'}>
          <div className={'d-flex gap-6'}>
            <div className={'col-2'}>
              <Box
                className={'d-flex gap-3 bg-white'}
                sx={{mb: 2}}
                style={{position: 'sticky', top: 0}}
              >
                <Autocomplete
                  fullWidth
                  renderInput={(params) => {
                    return <TextField {...params} fullWidth />
                  }}
                  value={{
                    value: selectedOfficeInfo?.officeId,
                    label: selectedOfficeInfo?.officeName,
                  }}
                  getOptionLabel={(option: any) =>
                    option.label === undefined ? '사업소를 입력해주세요.' : option.label
                  }
                  options={searchOfficeList}
                  onChange={(e, rowValue: any) => {
                    const {value} = rowValue
                    // setSearchOffice([value])
                    setSelected([value])
                  }}
                  disableClearable={true}
                />
              </Box>
              <div className={'d-flex gap-4 justify-content-center mb-5'}>
                <Button onClick={handleExpandClick} id={'openButton'}>
                  {expanded.length === 0 ? (
                    <span className={'fs-3 fw-bold'}>펼치기</span>
                  ) : (
                    <span className={'fs-3 fw-bold'}>접기</span>
                  )}
                </Button>
                <Button
                  onClick={() => {
                    getRefreshOfficeList()
                  }}
                  hidden={!userInfo?.accountType.includes('sys')}
                >
                  <span className={'fs-3 fw-bold'}>새로고침</span>
                </Button>
              </div>
              <Box className={'vh-100'} sx={{flexGrow: 1, maxHeight: 900, overflowY: 'auto'}}>
                {levelOffice1?.length > 0 && (
                  <TreeView
                    aria-label='controlled'
                    ref={treeViewRef}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                    onClick={() => {}}
                    multiSelect
                    sx={{
                      '& .MuiTreeItem-label': {
                        fontSize: '18px',
                      },
                    }}
                  >
                    {levelOffice1.map((el: any) => (
                      <StyledTreeItem
                        key={el.officeId}
                        nodeId={el.officeId}
                        label={el.officeName}
                        sx={{fontSize: '18px'}}
                        bgColor={'#f37005'}
                        color={'#fff'}
                      >
                        {levelOffice2
                          .filter((el2: any) => el2.officeId.slice(0, 2) === el.officeId)
                          .map((el2: any) => (
                            <TreeItem
                              key={el2.officeId}
                              nodeId={el2.officeId}
                              label={el2.officeName}
                            >
                              {levelOffice3
                                .filter((el3: any) => el3.officeId.slice(0, 4) === el2.officeId)
                                .map((el3: any) => (
                                  <TreeItem
                                    key={el3.officeId}
                                    nodeId={el3.officeId}
                                    label={el3.officeName}
                                  />
                                ))}
                            </TreeItem>
                          ))}
                      </StyledTreeItem>
                    ))}
                  </TreeView>
                )}
                {levelOffice1?.length === 0 && (
                  <TreeView
                    aria-label='controlled'
                    ref={treeViewRef}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                    onClick={() => {}}
                    multiSelect
                    sx={{
                      '& .MuiTreeItem-label': {
                        fontSize: '18px',
                      },
                    }}
                  >
                    {levelOffice2.map((el: any) => (
                      <StyledTreeItem
                        key={el.officeId}
                        nodeId={el.officeId}
                        label={el.officeName}
                        sx={{fontSize: '18px'}}
                        bgColor={'#f37005'}
                        color={'#fff'}
                      >
                        {levelOffice3
                          .filter((el3: any) => el3.officeId.slice(0, 4) === el.officeId)
                          .map((el3: any) => (
                            <TreeItem
                              key={el3.officeId}
                              nodeId={el3.officeId}
                              label={el3.officeName}
                            />
                          ))}
                      </StyledTreeItem>
                    ))}
                  </TreeView>
                )}
              </Box>
            </div>
            {selectedOfficeInfo === null ? (
              <div className={'col'}>
                <div className={'container h-100'}>
                  <div className={'text-center mt-9 row gap-3'}>
                    <span className={'fs-1 fw-bold mt-5'}>사업소를 선택해주세요.</span>
                    <span className={'fs-3 fw-bold mt-5'}>
                      사업소를 선택하여 해당 사업소의 기초정보들을 수정하거나 추가할 수 있습니다.
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {selectedOfficeInfo && (
                  <div className={'col'}>
                    <div className={'d-flex'}>
                      {/** LEFT *****************************************************************/}
                      <div
                        className={'col-4 row gap-5'}
                        style={{maxHeight: '1200px', minHeight: '500'}}
                      >
                        {/** IoT basic Info **********************************************************/}
                        <div className={'card'}>
                          <div className={'card-header fw-bold fs-1 align-items-center w-100'}>
                            <span>IoT 기초정보</span>
                          </div>
                          <div
                            className={'card-body'}
                            style={{height: '550px', overflowY: 'scroll'}}
                          >
                            <div className={'row gap-5'}>
                              {/** 내구연한 *****************************************************************/}
                              <div id={'durability'} className={'row gap-3'}>
                                <span className={'text-muted fs-4 fw-bold'}>
                                  내구연한 /{' '}
                                  <strong className={'text-danger'}>
                                    {' '}
                                    현재 : {selectedOfficeInfo?.durability} 년
                                  </strong>
                                </span>
                                <div className={'d-flex gap-3'}>
                                  <span className={'fs-2 fw-bold align-self-center '}>최대</span>
                                  <FormControl className={'col'}>
                                    <InputLabel id='demo-simple-select-label'>내구연한</InputLabel>
                                    <Select
                                      labelId='basicIot'
                                      id='basicIot'
                                      defaultValue={selectedOfficeInfo?.durability}
                                      label='내구연한'
                                      // onChange={handleChange}
                                    >
                                      {_.map(basicDefault.durability, (el: any, index: number) => (
                                        <MenuItem key={index} value={el}>
                                          {el}년
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              {/** 내구연한 알림 *****************************************************************/}
                              <div id={'durability_alert'} className={'row gap-3'}>
                                <span className={'text-muted fs-4 fw-bold'}>
                                  내구연한 만료 알림기간 /
                                  <strong className={'text-danger'}>
                                    {' '}
                                    현재 : {selectedOfficeInfo?.durabilityExpired} 년 전 알림
                                  </strong>
                                </span>
                                <div className={'d-flex gap-3'}>
                                  <span className={'fs-2 fw-bold align-self-center '}>잔여</span>
                                  <FormControl className={'col'}>
                                    <InputLabel id='demo-simple-select-label'>
                                      내구연한 만료 알림기간
                                    </InputLabel>
                                    <Select
                                      labelId='basicIot'
                                      id='durabilityExpired'
                                      defaultValue={selectedOfficeInfo?.durabilityExpired}
                                      label='내구연한 만료 알림기간'
                                      // onChange={handleChange}
                                    >
                                      {_.map(
                                        basicDefault.durabilityExpired,
                                        (el: any, index: number) => (
                                          <MenuItem key={index} value={el}>
                                            {el}년 전 알림
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              {/** 배터리잔존 경고 *****************************************************************/}
                              <div id={'battery_alert'} className={'row gap-3'}>
                                <span className={'text-muted fs-4 fw-bold'}>
                                  배터리잔존 경고 /{' '}
                                  <strong className={'text-danger'}>
                                    현재 : {selectedOfficeInfo?.batteryExpired} % 전 알림
                                  </strong>
                                </span>
                                <div className={'d-flex gap-3'}>
                                  <span className={'fs-2 fw-bold align-self-center '}>잔량</span>
                                  <FormControl className={'col'}>
                                    <InputLabel id='demo-simple-select-label'>
                                      배터리잔존 경고
                                    </InputLabel>
                                    <Select
                                      labelId='basicIot'
                                      id='durabilityExpired'
                                      defaultValue={selectedOfficeInfo?.batteryExpired}
                                      label='배터리잔존 경고'
                                      // onChange={handleChange}
                                    >
                                      {_.map(
                                        basicDefault.batteryExpired,
                                        (el: any, index: number) => (
                                          <MenuItem key={index} value={el}>
                                            {el}% 전
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              {/** 서버주기 *****************************************************************/}
                              {userInfo.accountType === 'sys_admin' && (
                                <div id={'serverCycle'} className={'row gap-3'}>
                                  <span className={'text-muted fs-4 fw-bold'}>서버주기</span>
                                  <div className={'d-flex justify-content-between'}>
                                    <div className={'d-flex col gap-3'}>
                                      <TextField
                                        variant={'outlined'}
                                        label={'서버주기'}
                                        type={'number'}
                                        disabled={selectedOfficeInfo?.officeId?.length === 2}
                                        className={'col-4'}
                                        autoComplete={'off'}
                                        defaultValue={serverCycle}
                                        onChange={(e) => {
                                          setServerCycle(Number(e.target.value))
                                        }}
                                      />
                                      <span className={'fs-4 fw-bold align-self-center col'}>
                                        시간 주기
                                      </span>
                                    </div>
                                    <div className={'col-2 align-self-center'}>
                                      <Button
                                        className={'fw-bold fs-5'}
                                        variant={'contained'}
                                        color={'primary'}
                                        size={'large'}
                                        disabled={selectedOfficeInfo?.officeId?.length === 2}
                                        onClick={() => {
                                          serverCycleUpdate(serverCycle)
                                        }}
                                      >
                                        저장
                                      </Button>
                                    </div>
                                  </div>
                                  <span className={'fw-bold fs-5 text-danger'}>
                                    해당 사업소의 모든 단말기의 서버주기를 변경합니다.
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/** MEterarium basic Info **********************************************************/}
                        <div
                          className={'card '}
                          id={'customer-basicInfo'}
                          style={{maxHeight: '480px'}}
                        >
                          <div className={'card-header fw-bold fs-1 align-items-center w-100'}>
                            <span>Meterarium 기초정보</span>
                          </div>
                          <div
                            className={'card-body pt-3'}
                            style={{maxHeight: '400px', overflowY: 'scroll'}}
                          >
                            <div
                              className={'align-self-center d-flex gap-4 mb-5 justify-content-end'}
                            >
                              <Button
                                variant={'contained'}
                                type={'button'}
                                className={'fw-bold fs-7'}
                                data-bs-toggle='modal'
                                data-bs-target='#customerAddModal'
                                onClick={() => {
                                  setModalType('대상')
                                }}
                              >
                                수용가 대상추가
                              </Button>
                              <Button
                                variant={'contained'}
                                type={'button'}
                                color={'secondary'}
                                className={'fw-bold fs-7'}
                                data-bs-toggle='modal'
                                data-bs-target='#customerAddModal'
                                onClick={() => {
                                  setModalType('업종')
                                }}
                              >
                                수용가 업종추가
                              </Button>
                            </div>
                            <div className={'row row-cols-2 gap-4'}>
                              <div className={'col-lg'}>
                                <div className={'row gap-3'}>
                                  <Chip
                                    label='수용가 대상'
                                    color='info'
                                    variant='outlined'
                                    sx={{fontSize: '13px'}}
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      '& > :not(style)': {
                                        p: 2,
                                        width: '100%',
                                        height: '100%',
                                      },
                                    }}
                                  >
                                    <Paper elevation={8}>
                                      {selectedOfficeInfo?.officeCustomerTypeModel?.length <= 0 && (
                                        <div className={'text-center'}>
                                          <span className={'text-muted fs-6'}>
                                            등록된 대상이 없습니다.
                                          </span>
                                        </div>
                                      )}
                                      <table className='col-lg table table-hover table-rounded table-row-bordered border border-2 gy-4 gs-7 mb-0'>
                                        <tbody>
                                          {_.map(
                                            selectedOfficeInfo?.officeCustomerTypeModel,
                                            (el: any, index: number) => {
                                              return (
                                                <tr className={'text-center'} key={index}>
                                                  <td
                                                    className={'fs-5 fw-bolder cursor-pointer'}
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#typeUpdateModal'
                                                    onClick={() => {
                                                      setSelectedType(el)
                                                    }}
                                                  >
                                                    {el?.customerTypeName}
                                                  </td>
                                                </tr>
                                              )
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </Paper>
                                  </Box>
                                </div>
                              </div>
                              <div className={'col-lg'}>
                                <div className={'row gap-3'}>
                                  <Chip
                                    label='수용가 업종'
                                    color='secondary'
                                    variant='outlined'
                                    sx={{fontSize: '13px'}}
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      '& > :not(style)': {
                                        p: 2,
                                        width: '100%',
                                        height: '100%',
                                      },
                                    }}
                                  >
                                    <Paper elevation={8}>
                                      {selectedOfficeInfo?.officeCustomerWorkTypeModel?.length <=
                                        0 && (
                                        <div className={'text-center'}>
                                          <span className={'text-muted fs-6'}>
                                            등록된 업종이 없습니다.
                                          </span>
                                        </div>
                                      )}
                                      <table className='col-lg table table-hover table-rounded table-row-bordered border border-2 gy-4 gs-7 mb-0'>
                                        <tbody>
                                          {_.map(
                                            selectedOfficeInfo?.officeCustomerWorkTypeModel,
                                            (el: any, index: number) => {
                                              return (
                                                <tr className={'text-center'} key={index}>
                                                  <td
                                                    className={'fs-5 fw-bolder cursor-pointer'}
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#workTypeUpdateModal'
                                                    onClick={() => {
                                                      setSelectedWorkType(el)
                                                    }}
                                                  >
                                                    {el?.customerWorkTypeName}
                                                  </td>
                                                </tr>
                                              )
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </Paper>
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/** RIGHT *****************************************************************/}
                      <div className={'row gap-5'} style={{maxHeight: '850px'}}>
                        <div id={'customer-notification'} className={'col-lg'}>
                          <div className={'card'}>
                            <div className={'card-header'}>
                              <div className={'card-title'}>
                                <h3>수용가 알람정보</h3>
                              </div>
                            </div>
                            <div
                              className={'card-body ms-5'}
                              style={{maxHeight: '850px', overflowY: 'scroll'}}
                            >
                              {selectedOfficeInfo?.officeCustomerTypeModel?.length <= 0 && (
                                <div className={'text-center'}>
                                  <span className={'text-muted fs-6'}>
                                    등록된 알람정보가 없습니다.
                                  </span>
                                  <br />
                                  <span className={'text-muted fs-6'}>
                                    수용가 대상 먼저 추가해주세요.
                                  </span>
                                </div>
                              )}
                              {_.map(
                                selectedOfficeInfo?.officeCustomerTypeModel,
                                (el: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      className={'border-bottom border-gray-400 border-3 my-5 pb-2'}
                                    >
                                      <div className={'row row-cols-2'}>
                                        <div className={'col-lg row gap-2 ms-3'}>
                                          <span
                                            className={
                                              'text-muted fs-6 p-1 text-hover-primary fw-bolder '
                                            }
                                          >
                                            {el?.customerTypeName} 저사용량 경고기준
                                          </span>
                                          <div className={'d-flex col-lg gap-3'}>
                                            <FormControl className={'col-lg'}>
                                              <InputLabel id='demo-simple-select-label'>
                                                주기
                                              </InputLabel>
                                              <Select
                                                labelId='demo-simple-select-label'
                                                id='customer-target'
                                                label='주기'
                                                onChange={(e: any) => {
                                                  setUpdateCustomerDetailInfo({
                                                    ...updateCustomerDetailInfo,
                                                    [el.customerTypeName]: {
                                                      ...updateCustomerDetailInfo[
                                                        el.customerTypeName
                                                      ],
                                                      lowUseCycle: e.target.value,
                                                    },
                                                  })
                                                }}
                                                defaultValue={el?.lowUseCycle}
                                              >
                                                <MenuItem value={'일'}>일</MenuItem>
                                                <MenuItem value={'주'}>주</MenuItem>
                                                <MenuItem value={'월'}>월</MenuItem>
                                              </Select>
                                            </FormControl>
                                            <span
                                              className={
                                                'align-self-center fs-4 fw-bolder me-3 col-lg-1 text-center'
                                              }
                                            >
                                              평균
                                            </span>
                                            <TextField
                                              label={'저사용량 경고기준'}
                                              className={'col-lg'}
                                              helperText={
                                                <span className={'fs-6 fw-bolder'}>
                                                  숫자만 입력가능 ex) 15
                                                </span>
                                              }
                                              placeholder={'숫자만 입력해주세요.'}
                                              type={'number'}
                                              variant={'standard'}
                                              defaultValue={el?.lowUseStandard}
                                              onChange={(e) => {
                                                setUpdateCustomerDetailInfo({
                                                  ...updateCustomerDetailInfo,
                                                  [el.customerTypeName]: {
                                                    ...updateCustomerDetailInfo[
                                                      el.customerTypeName
                                                    ],
                                                    lowUseStandard: Number(e.target.value),
                                                  },
                                                })
                                              }}
                                            />
                                            <FormControl className={'col-lg'}>
                                              <InputLabel id='demo-simple-select-label'>
                                                단위 기준
                                              </InputLabel>
                                              <Select
                                                labelId='demo-simple-select-label'
                                                id='customer-target'
                                                label='단위 기준'
                                                defaultValue={el?.lowUseUnit}
                                                onChange={(e: any) => {
                                                  setUpdateCustomerDetailInfo({
                                                    ...updateCustomerDetailInfo,
                                                    [el.customerTypeName]: {
                                                      ...updateCustomerDetailInfo[
                                                        el.customerTypeName
                                                      ],
                                                      lowUseUnit: e.target.value,
                                                    },
                                                  })
                                                }}
                                              >
                                                <MenuItem value={'%'}>%</MenuItem>
                                                <MenuItem value={'t'}>t</MenuItem>
                                              </Select>
                                            </FormControl>
                                            <span
                                              className={'align-self-center fs-4 fw-bolder ms-3'}
                                            >
                                              이상
                                            </span>
                                          </div>
                                          <span
                                            className={
                                              'text-muted fs-6 p-1 text-hover-danger fw-bolder '
                                            }
                                          >
                                            {el?.customerTypeName} 누수의심 경고기준
                                          </span>
                                          <div className={'d-flex col-lg gap-3'}>
                                            <FormControl className={'col-lg'}>
                                              <InputLabel id='demo-simple-select-label'>
                                                주기
                                              </InputLabel>
                                              <Select
                                                labelId='demo-simple-select-label'
                                                id='customer-target'
                                                label='주기'
                                                onChange={(e: any) => {
                                                  setUpdateCustomerDetailInfo({
                                                    ...updateCustomerDetailInfo,
                                                    [el.customerTypeName]: {
                                                      ...updateCustomerDetailInfo[
                                                        el.customerTypeName
                                                      ],
                                                      leakUseCycle: e.target.value,
                                                    },
                                                  })
                                                }}
                                                defaultValue={el?.leakUseCycle}
                                              >
                                                <MenuItem value={'일'}>일</MenuItem>
                                                <MenuItem value={'주'}>주</MenuItem>
                                                <MenuItem value={'월'}>월</MenuItem>
                                              </Select>
                                            </FormControl>
                                            <span
                                              className={
                                                'align-self-center fs-4 fw-bolder me-3 col-lg-1 text-center'
                                              }
                                            >
                                              평균
                                            </span>
                                            <TextField
                                              label={'누수의심 경고기준'}
                                              helperText={
                                                <span className={'fs-6 fw-bolder'}>
                                                  숫자만 입력가능 ex) 30
                                                </span>
                                              }
                                              className={'col-lg'}
                                              placeholder={'숫자만 입력해주세요.'}
                                              type={'number'}
                                              variant={'standard'}
                                              defaultValue={el?.leakUseStandard}
                                              onChange={(e) => {
                                                setUpdateCustomerDetailInfo({
                                                  ...updateCustomerDetailInfo,
                                                  [el.customerTypeName]: {
                                                    ...updateCustomerDetailInfo[
                                                      el.customerTypeName
                                                    ],
                                                    leakUseStandard: Number(e.target.value),
                                                  },
                                                })
                                              }}
                                            />
                                            <FormControl className={'col-lg'}>
                                              <InputLabel id='demo-simple-select-label'>
                                                단위 기준
                                              </InputLabel>
                                              <Select
                                                labelId='demo-simple-select-label'
                                                id='customer-target'
                                                label='단위 기준'
                                                defaultValue={el?.leakUseUnit}
                                                onChange={(e: any) => {
                                                  setUpdateCustomerDetailInfo({
                                                    ...updateCustomerDetailInfo,
                                                    [el.customerTypeName]: {
                                                      ...updateCustomerDetailInfo[
                                                        el.customerTypeName
                                                      ],
                                                      leakUseUnit: e.target.value,
                                                    },
                                                  })
                                                }}
                                              >
                                                <MenuItem value={'%'}>%</MenuItem>
                                                <MenuItem value={'t'}>t</MenuItem>
                                              </Select>
                                            </FormControl>
                                            <span
                                              className={'align-self-center fs-4 fw-bolder ms-3'}
                                            >
                                              이상
                                            </span>
                                          </div>
                                          {el.customerTypeName == '일반' && (
                                            <>
                                              <span
                                                className={
                                                  'text-muted fs-6 p-1 text-hover-danger fw-bolder '
                                                }
                                              >
                                                {el?.customerTypeName} 장기미사용 경고기준
                                              </span>
                                              <div className={'d-flex col-3 gap-3'}>
                                                <TextField
                                                  label={'장기미사용 경고기준'}
                                                  helperText={
                                                    <span className={'fs-6 fw-bolder'}>
                                                      숫자만 입력가능 ex) 30
                                                    </span>
                                                  }
                                                  className={'col-lg'}
                                                  placeholder={'숫자만 입력해주세요.'}
                                                  type={'number'}
                                                  variant={'standard'}
                                                  defaultValue={el?.longtermStandard}
                                                  onChange={(e) => {
                                                    setUpdateCustomerDetailInfo({
                                                      ...updateCustomerDetailInfo,
                                                      [el.customerTypeName]: {
                                                        ...updateCustomerDetailInfo[
                                                          el.customerTypeName
                                                        ],
                                                        longtermStandard: Number(e.target.value),
                                                      },
                                                    })
                                                  }}
                                                />
                                                <span
                                                  className={
                                                    'align-self-center fs-4 fw-bolder me-3 col-lg-1 text-center'
                                                  }
                                                >
                                                  일
                                                </span>
                                              </div>
                                            </>
                                          )}

                                          {/* <span
                                          className={
                                            'text-muted fs-6 p-1 text-hover-danger fw-bolder '
                                          }
                                        >
                                          {el?.customerTypeName} 동파경보 경고기준
                                        </span>
                                        <div className={'d-flex col-lg gap-3'}>
                                          <FormControl className={'col-lg'}>
                                            <InputLabel id='demo-simple-select-label'>
                                              주기
                                            </InputLabel>
                                            <Select
                                              labelId='demo-simple-select-label'
                                              id='customer-target'
                                              label='주기'
                                              onChange={(e: any) => {
                                                setUpdateCustomerDetailInfo({
                                                  ...updateCustomerDetailInfo,
                                                  [el.customerTypeName]: {
                                                    ...updateCustomerDetailInfo[
                                                      el.customerTypeName
                                                    ],
                                                    leakUseCycle: e.target.value,
                                                  },
                                                })
                                              }}
                                              defaultValue={el?.leakUseCycle}
                                            >
                                              <MenuItem value={'일'}>일</MenuItem>
                                              <MenuItem value={'주'}>주</MenuItem>
                                              <MenuItem value={'월'}>월</MenuItem>
                                            </Select>
                                          </FormControl>
                                          <span
                                            className={
                                              'align-self-center fs-4 fw-bolder me-3 col-lg-1 text-center'
                                            }
                                          >
                                            평균
                                          </span>
                                          <TextField
                                            label={'동파경보 경고기준'}
                                            helperText={
                                              <span className={'fs-6 fw-bolder'}>
                                                숫자만 입력가능 ex) 30
                                              </span>
                                            }
                                            className={'col-lg'}
                                            placeholder={'숫자만 입력해주세요.'}
                                            type={'number'}
                                            variant={'standard'}
                                            defaultValue={el?.leakUseStandard}
                                            onChange={(e) => {
                                              setUpdateCustomerDetailInfo({
                                                ...updateCustomerDetailInfo,
                                                [el.customerTypeName]: {
                                                  ...updateCustomerDetailInfo[el.customerTypeName],
                                                  leakUseStandard: Number(e.target.value),
                                                },
                                              })
                                            }}
                                          />
                                          <FormControl className={'col-lg'}>
                                            <InputLabel id='demo-simple-select-label'>
                                              단위 기준
                                            </InputLabel>
                                            <Select
                                              labelId='demo-simple-select-label'
                                              id='customer-target'
                                              label='단위 기준'
                                              defaultValue={el?.leakUseUnit}
                                              onChange={(e: any) => {
                                                setUpdateCustomerDetailInfo({
                                                  ...updateCustomerDetailInfo,
                                                  [el.customerTypeName]: {
                                                    ...updateCustomerDetailInfo[
                                                      el.customerTypeName
                                                    ],
                                                    leakUseUnit: e.target.value,
                                                  },
                                                })
                                              }}
                                            >
                                              <MenuItem value={'%'}>%</MenuItem>
                                              <MenuItem value={'t'}>t</MenuItem>
                                            </Select>
                                          </FormControl>
                                          <span className={'align-self-center fs-4 fw-bolder ms-3'}>
                                            이상
                                          </span>
                                        </div> */}
                                          {/*  */}
                                        </div>
                                      </div>
                                      <div className={'text-end pb-3'}>
                                        <Button
                                          variant={'contained'}
                                          type={'button'}
                                          className={'fw-bold fs-6'}
                                          size={'large'}
                                          onClick={() => {
                                            typeOfInfoUpdate(el)
                                          }}
                                        >
                                          저장
                                        </Button>
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={'card text-end'}>
                          <div className={'card-body p'}>
                            <Button
                              variant={'contained'}
                              className={'fw-bold fs-6'}
                              color={'error'}
                              size={'large'}
                              onClick={() => deleteOffice()}
                            >
                              사업소 삭제
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {isMobileDevice() && (
        <div className={'card card-body py-4 flex-grow-1 mb-xl-10 mb-5'}>
          <div className='notice d-flex bg-light-danger rounded border-warning border border-dashed p-6 '>
            <KTSVG
              path='icons/duotune/general/gen044.svg'
              className='svg-icon-2tx svg-icon-warning me-4'
            />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>
                  <WarningIcon color={'error'} /> PC에서 설정해주세요.
                </h4>
                <div className='fs-6 text-gray-600'>
                  모바일에서 사용할 수 없는 기능입니다. PC에서 설정해주세요.
                  <a
                    className='fw-bolder'
                    href='https://docs.google.com/forms/d/e/1FAIpQLScq6EIw427I3xcyeugAJ7SUnfqk86BzwBk9Y_oZloLtx3E4mQ/viewform'
                  >
                    YOUNG-IT
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomerAddModal
        modalType={modalType}
        selectedOfficeInfo={selectedOfficeInfo}
        setDetailInfoFlag={setDetailInfoFlag}
        detailInfoFlag={detailInfoFlag}
        {...props}
      />
      <TypeUpdateModal
        selectedOfficeInfo={selectedOfficeInfo}
        taost={toast}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        setDetailInfoFlag={setDetailInfoFlag}
        detailInfoFlag={detailInfoFlag}
      />
      <WorkTypeUpdateModal
        taost={toast}
        selectedWorkType={selectedWorkType}
        setSelectedWorkType={setSelectedWorkType}
        setDetailInfoFlag={setDetailInfoFlag}
        detailInfoFlag={detailInfoFlag}
      />
      <ToastContainer />
    </>
  )
}
export default OfficeConfigSetup
