import {DoubleArrow} from '@mui/icons-material'
import CachedIcon from '@mui/icons-material/Cached'
import {Autocomplete, Button, Chip, TextField} from '@mui/material'
import dayjs from 'dayjs'
import {envImagePath} from 'GLOBAL/envVariables'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getInstallList, imageRefresh} from 'utils/apiCalling/api'
import {globalConst, globalText} from 'utils/const/globalConst'
import {installDevicedBmToolTip} from 'utils/toolTips/toolTipText'

const SelectInstallableDevice = (props: any) => {
  const {set, setRenderStep, step, installSubmit} = props
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [installableList, setInstallableList] = useState<any>(null)
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false)
  const [selectInstallType, setSelectInstallType] = useState<any>(null)

  const getInstallListCall = () => {
    getInstallList(userInfo, 'installDevice', null, {
      success: (res: any) => {
        setInstallableList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  const imageRefreshCall = () => {
    imageRefresh(installSubmit, {
      success: (res: any) => {
        set((prev: any) => ({
          ...prev,
          imei: res.imei,
          idxDate: res.idxDate,
          filePath: res.filePath,
          createAt: res.createAt,
        }))
      },
      fail: (err: any) => {
        console.log(err)
        setRefreshLoading(false)
      },
      loading: (loading: boolean) => {
        setRefreshLoading(loading)
      },
    })
  }

  useEffect(() => {
    if (step === 1) {
      getInstallListCall()
    }
  }, [step])

  useEffect(() => {
    setSelectInstallType(null)
  }, [installSubmit])

  return (
    <div className={'row mx-0 justify-content-center pb-3'}>
      <Autocomplete
        fullWidth
        id='choose-device'
        options={installableList ?? []}
        className={'px-0'}
        disableClearable={true}
        clearText={'초기화'}
        noOptionsText={'검색 결과가 없습니다.'}
        getOptionLabel={(option: any) => `${option?.serialNumber} - ${option?.deviceTypeName}`}
        onChange={(e, rowValue: any) => {
          set((prev: any) => ({
            ...prev,
            brightness: Number(rowValue?.brightness),
            contrast: Number(rowValue?.contrast),
            createAt: rowValue?.createAt,
            deviceType: rowValue?.deviceType,
            deviceTypeName: rowValue?.deviceTypeName,
            filePath: rowValue?.filePath,
            imei: rowValue?.imei,
            idxDate: rowValue?.idxDate,
            serialNumber: rowValue?.serialNumber,
            rssiStatus: rowValue?.rssiStatus,
            temperature: rowValue?.temperature,
          }))
        }}
        sx={{fontSize: '1.5rem', color: '#fff'}}
        renderInput={(params) => (
          <div className={'d-flex gap-3'}>
            <TextField
              {...params}
              fullWidth
              label='단밀기 선택'
              focused
              placeholder={'단말기를 선택해주세요.'}
            />
          </div>
        )}
      />

      {installSubmit?.imei && (
        <div className={'my-2 p-2 border border-gray-400 rounded-2'}>
          {refreshLoading ? (
            <div className={'d-flex justify-content-center align-items-center my-5'}>
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {installSubmit?.deviceType === 'analog' ? (
                <>
                  <div className={'w-100 d-grid justify-content-center'} style={{height: 144}}>
                    <img
                      // src={'https://meterarium.com:5280' + installSubmit?.filePath}
                      src={`${envImagePath}${installSubmit?.filePath}`}
                      alt={'device'}
                      style={{width: 320}}
                    />
                  </div>

                  <div className={'d-flex w-100 justify-content-start align-items-end mt-2 gap-2'}>
                    <Chip
                      label={`전파 세기 : ${installSubmit?.rssiStatus}dBm `}
                      color={
                        Number(installSubmit?.rssiStatus) < globalConst.rssiWarningdBm
                          ? 'error'
                          : 'success'
                      }
                      variant={'filled'}
                      className={'fs-4'}
                    />

                    {Number(installSubmit?.temperature || 99) < 90 && (
                      <Chip
                        label={`온도 : ${installSubmit?.temperature}°C`}
                        color={'success'}
                        variant={'filled'}
                        className={'fs-4'}
                      />
                    )}
                  </div>
                  <div className={'d-flex w-100 justify-content-end align-items-end mt-2'}>
                    <span className={'text-end fs-3 fw-bold'}>
                      {dayjs(installSubmit?.createAt).format('YY.MM.DD HH시 mm분 ss초')}
                    </span>
                  </div>

                  <div className={'alert alert-dark mt-2'}>
                    <span className={'fs-3 fw-bold'}>설치 방식을 선택해주세요</span>
                    <div className={'d-flex justify-content-around align-items-end mt-2'}>
                      <Chip
                        label={`${globalText.installInside}`}
                        color={'primary'}
                        variant={selectInstallType === 'N' ? 'filled' : 'outlined'}
                        className={'fs-4'}
                        onClick={() => setSelectInstallType('N')}
                      />

                      <Chip
                        label={`${globalText.installOutside}`}
                        color={'primary'}
                        variant={selectInstallType === 'OUT' ? 'filled' : 'outlined'}
                        className={'fs-4'}
                        onClick={() => setSelectInstallType('OUT')}
                      />
                    </div>
                  </div>
                  {selectInstallType != null &&
                    (installSubmit?.rssiStatus &&
                    Number(installSubmit?.rssiStatus) < globalConst.rssiWarningdBm
                      ? installDevicedBmToolTip(selectInstallType)
                      : installDevicedBmToolTip('success'))}

                  <div className={'my-2 d-flex gap-2'}>
                    <Button
                      variant={'contained'}
                      color={'warning'}
                      fullWidth
                      startIcon={<CachedIcon />}
                      onClick={() => imageRefreshCall()}
                    >
                      새로고침
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <h5 className={'text-center text-danger'}>디지털 단말기입니다.</h5>
                  <div className={'d-flex w-100 justify-content-start align-items-end mt-2 gap-2'}>
                    <Chip
                      label={`전파 세기 : ${installSubmit?.rssiStatus}dBm `}
                      color={
                        Number(installSubmit?.rssiStatus) < globalConst.rssiWarningdBm
                          ? 'error'
                          : 'success'
                      }
                      variant={'filled'}
                      className={'fs-4'}
                    />

                    {Number(installSubmit?.temperature || 99) < 90 && (
                      <Chip
                        label={`온도 : ${installSubmit?.temperature}°C`}
                        color={'success'}
                        variant={'filled'}
                        className={'fs-4'}
                      />
                    )}
                  </div>
                  <div className={'d-flex w-100 justify-content-end align-items-end mt-2'}>
                    <span className={'text-end fs-3 fw-bold'}>
                      {dayjs(installSubmit?.createAt).format('YY.MM.DD HH시 mm분 ss초')}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}

      <Button
        variant={'contained'}
        color={'primary'}
        fullWidth
        disabled={installSubmit?.imei === null || selectInstallType === null}
        // disabled={installSubmit?.imei === null}
        style={{
          position: !installSubmit?.imei ? 'fixed' : 'static',
          bottom: '10px',
          marginTop: installSubmit?.imei ? '10px' : '0px',
        }}
        endIcon={<DoubleArrow />}
        onClick={() => {
          if (installSubmit?.deviceType === 'analog') {
            setRenderStep(2)
          } else if (installSubmit?.deviceType === 'digital') {
            setRenderStep(3)
            set((prev: any) => ({
              ...prev,
              idxDate: '20230626',
              xstart: 0,
              ystart: 0,
              xend: 0,
              yend: 0,
              recognizedType: null,
              rotate: 0,
            }))
          }
        }}
      >
        단말기 선택 완료
      </Button>
    </div>
  )
}
export default SelectInstallableDevice
