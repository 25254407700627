import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import {Button, Chip, Fade, TextField, Tooltip} from '@mui/material'
import {envImagePath} from 'GLOBAL/envVariables'
import {
  defaultImage,
  deviceError,
  deviceSuccess,
  noImage,
  selectFailEFos,
  selectFailImg,
  selectSuccessEfos,
  selectSuccessImg,
  yMarker,
} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import _ from 'lodash'
import ActivityMonitoringChart from 'pages/customer/charts/ActivityMonitoringChart'
import {LeakViewChart} from 'pages/customer/charts/LeakViewChart'
import {Map, MapMarker} from 'react-kakao-maps-sdk'
import {requestAsCancelNew, requestAsNew} from 'utils/apiCalling/api'
import {entimoreController, globalFormatWithHyphens} from 'utils/const/globalConst'
import {handleCellClick} from 'utils/func/justUtils'
import {LeakViewChartBongHwa} from '../charts/LeakViewChartBongHwa'
import {TemperatureHistoryChart} from '../charts/TemperatureHistoryChart'

export const customerActivityColumns = (filter: any) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <div className={'row text-center gap-3'}>
            <div>
              <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
            </div>
            <div className={'d-flex gap-3 justify-content-center'}>
              <img
                alt={'suc-device'}
                src={
                  params?.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
                }
                style={{width: '50px'}}
              />

              <>
                <img
                  alt={'efos'}
                  src={
                    params?.row.efosStatus
                      ? selectSuccessEfos(cooperIdx)
                      : selectFailEFos(cooperIdx)
                  }
                  style={{width: '50px'}}
                />
              </>
            </div>
            <span>{params.row.cooperName}</span>
          </div>
        )
      },
    },
    {
      field: 'address',
      headerName: '주소',
      cellClassName: 'align-items-center',
      renderCell: (params: any) => {
        return (
          <div className={'row'}>
            <div className={'mb-2'}>이름 : {params.row.customerName}</div>
            <div className={'mb-2'}>주소 : {params.row.address}</div>
            <div className={'mb-2'}>담당자 : {params.row.managerName}</div>
            <div style={{height: '100px', width: '200px'}}>
              <Map // 지도를 표시할 Container
                center={{
                  // 지도의 중심좌표
                  lat: params.row?.latitude,
                  lng: params.row?.longitude,
                }}
                style={{
                  // 지도의 크기
                  width: '100%',
                  height: '90%',
                }}
                level={2} // 지도의 확대 레벨
                mapTypeId={kakao.maps.MapTypeId.SKYVIEW}
                zoomable={false}
                draggable={false}
              >
                {/*<MapTypeControl position={kakao.maps.ControlPosition.TOPRIGHT} />*/}
                <MapMarker // 마커를 생성합니다
                  position={{
                    // 마커가 표시될 위치입니다
                    lat: params.row?.latitude,
                    lng: params.row?.longitude,
                  }}
                  image={{src: yMarker, size: {width: 30, height: 40}}}
                />
              </Map>
            </div>
          </div>
        )
      },
      width: 200,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'chart',
      headerName: '차트',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.checkModelList?.length === 0 ? (
              <span className={'fw-bold'}>데이터가 존재하지 않습니다.</span>
            ) : (
              <ActivityMonitoringChart filter={filter} monitoringList={params.row.checkModelList} />
            )}
          </>
        )
      },
      width: 1200,
      // cellClassName: 'h-100 w-100',
      align: 'left',
      headerAlign: 'center',
    },
  ]
}

export const customerAllocationColumns = (setToInstallable: (row: any) => void) => {
  return [
    {
      field: 'officeName',
      headerName: '사업소',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.officeId}</span>
            <span>{params.row.officeName}</span>
          </div>
        )
      },
    },
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <>{globalFormatWithHyphens(params?.row.officeId, params?.row.customerNumber)}</>
      },
    },
    {
      field: 'customerName',
      headerName: '이름',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'address',
      headerName: '주소',
      width: 500,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row gap-1'}>
            <span>
              {params.row.addressRoad} / {params.row.addressDetail}
            </span>
            <span>
              {params.row.addressNumber} / {params.row.addressDetail}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerStatus',
      headerName: '설치 가능여부',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.customerStatus === 'impossible' ? (
              <Button
                variant={'contained'}
                color={'success'}
                startIcon={<ChangeCircleIcon fontSize={'large'} />}
                onClick={async (e) => {
                  handleCellClick(e)
                  setToInstallable(params.row)
                }}
              >
                설치 가능변경
              </Button>
            ) : (
              <Chip label='설치가능' color={'primary'} sx={{fontSize: '15px'}} />
            )}
          </>
        )
      },
    },
    {
      field: 'allocationStatus',
      headerName: '할당내역',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.assignStatus === true ? '할당' : '미할당'}</span>
          </div>
        )
      },
    },
    {
      field: 'assignFromName',
      headerName: '할당자',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.assignFromName === null ? '-' : params.row.assignFromName}</span>
          </div>
        )
      },
    },
    {
      field: 'assignToName',
      headerName: '설치자',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.assignToName === null ? '-' : params.row.assignToName}</span>
          </div>
        )
      },
    },
  ]
}

export const customerInfoColumns = (
  imageDetailModalOpen: () => void,
  deviceDetailModalOpen: () => void,
  customerDetailModalOpen: () => void,
  setCustomerDetailData: any,
  setDeviceDetailData: any
) => {
  const chipValue = (value: string) => {
    switch (value) {
      case null:
        return '기계식'
      case 'analog':
        return '기계식'
      case 'digital':
        return '디지털'
      case 'gas':
        return '가스'
      default:
        return '기계식'
    }
  }
  const chipColor = (value: string) => {
    switch (value) {
      case null:
        return 'error'
      case 'analog':
        return 'error'
      case 'digital':
        return 'warning'
      case 'gas':
        return 'success'
      default:
        return 'error'
    }
  }
  return [
    {
      field: 'officeName',
      headerName: '사업소 명',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.officeId}</span>
            <span>{params.row.officeName}</span>
          </div>
        )
      },
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        return (
          <>
            <div
              onClick={(e) => {
                handleCellClick(e)
                setCustomerDetailData(params.row)
                customerDetailModalOpen()
              }}
              className={'row w-100 h-100 text-center '}
              style={{alignContent: 'center'}}
            >
              <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
              <span className={'text-danger '}>
                {params.row.customerStatus === null
                  ? null
                  : params.row.customerStatus === 'as'
                  ? 'A/S'
                  : params.row.customerStatus === 'move'
                  ? '이전 철거'
                  : '철거'}
              </span>
            </div>
          </>
        )
      },
    },

    {
      field: 'customerInfo',
      headerName: '주소',
      renderCell: (params: any) => {
        return (
          <div className={'row'}>
            <span className={''}>{params.row.customerName}</span>
            <span>
              <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.addressRoad}{' '}
            </span>
            <span>
              <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.addressNumber}{' '}
            </span>
            {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )}
            {params.row.managerName !== 'NULL' && (
              <span>
                <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 </strong> :{' '}
                {params.row.managerName}{' '}
              </span>
            )}
            <span>
              <strong style={{color: '#038109'}}>설치자 </strong> : {params.row.installerName}{' '}
            </span>
          </div>
        )
      },
      width: 350,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'deviceInfo',
      headerName: '배터리',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.serialNumber}</span>
            <span className={`${params.row.batteryLevel < 30 ? 'text-danger' : 'text-dark'}`}>
              {params.row.batteryLevel} %
            </span>
          </div>
        )
      },
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerDetail',
      headerName: '온도',
      width: 100,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        const temp = params.row.temperature
        return (
          <div className={'row text-center'}>
            <span>{params.row.meterDiameter} mm</span>
            <span>{temp < 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },

    {
      field: 'efosStatus',
      headerName: '계량기 통신상태',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <div
              className={'row w-100 h-100 text-center '}
              style={{alignContent: 'center'}}
              onClick={(e) => {
                handleCellClick(e)
                setDeviceDetailData(params.row)
                deviceDetailModalOpen()
              }}
            >
              <img
                src={
                  params?.row.efosStatus ? selectSuccessEfos(cooperIdx) : selectFailEFos(cooperIdx)
                }
                width={100}
                alt={'efos-check'}
              />
            </div>
          </>
        )
      },
      width: 160,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceStatus',
      headerName: '단말기 통신상태',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <div
            className={'row w-100 h-100 text-center '}
            style={{alignContent: 'center'}}
            onClick={(e) => {
              handleCellClick(e)
              setDeviceDetailData(params.row)
              deviceDetailModalOpen()
            }}
          >
            <img
              alt={'device-check'}
              src={
                params?.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
              }
              width={100}
            />
          </div>
        )
      },
      width: 160,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',

      headerAlign: 'center',
      renderCell: (params: any) => {
        const rotate: string = params.row.rotate
        return (
          <>
            {params.row.deviceType !== 'analog' ? (
              <img
                className={'cursor-pointer'}
                src={defaultImage}
                alt={'default'}
                onClick={(e) => {
                  setDeviceDetailData(params.row)
                  handleCellClick(e)
                  imageDetailModalOpen()
                }}
              />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px', zIndex: 888}}
                >
                  {params.row.rotate !== 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {/*{params.row.recognizedType === null || params.row.recognizedType === 'analog' ? (*/}
                  {/*  <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />*/}
                  {/*) : (*/}
                  {/*  <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />*/}
                  {/*)}*/}
                  <Chip
                    label={chipValue(params.row.recognizedType)}
                    color={chipColor(params.row.recognizedType)}
                    sx={{fontSize: '17px'}}
                  />
                </div>
                <img
                  alt={'device'}
                  className={'cursor-pointer'}
                  src={
                    params.row.filePath === null ? noImage : `${envImagePath}${params.row.filePath}`
                  }
                  style={{
                    transform: params.row.filePath
                      ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                        (Number(rotate) < -90 && Number(rotate) > -270)
                        ? `rotate(${rotate}deg)`
                        : `rotate(0deg)`
                      : `rotate(0deg)`,
                  }}
                  width={320}
                  height={144}
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    imageDetailModalOpen()
                  }}
                />
              </div>
            )}
          </>
        )
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침일',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.efosConnectAt).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}
export const customerInfoBoardVersionColumns = (
  imageDetailModalOpen: () => void,
  deviceDetailModalOpen: () => void,
  customerDetailModalOpen: () => void,
  setCustomerDetailData: any,
  setDeviceDetailData: any,
  userInfo: any,
  getcustomerInfo: () => void,
  hiddenClick: boolean
) => {
  const chipValue = (value: string) => {
    switch (value) {
      case null:
        return '기계식'
      case 'analog':
        return '기계식'
      case 'digital':
        return '디지털'
      case 'gas':
        return '가스'
      default:
        return '기계식'
    }
  }
  const chipColor = (value: string) => {
    switch (value) {
      case null:
        return 'error'
      case 'analog':
        return 'error'
      case 'digital':
        return 'warning'
      case 'gas':
        return 'success'
      default:
        return 'error'
    }
  }
  return [
    {
      field: 'officeName',
      headerName: '사업소 명',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate gap-2'}>
            <span className='text-truncate'>{params.row.officeId}</span>
            <span className='text-truncate'>{params.row.officeName}</span>
            <span className='text-truncate '>{params.row.cooperName}</span>
          </div>
        )
      },
      width: 120,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerNumber',
      headerName: 'A/S',
      width: 190,
      flex: 1,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        return (
          <>
            <div
              className={'row w-100 h-100 text-center text-truncate'}
              style={{alignContent: 'center'}}
            >
              <span
                onClick={(e) => {
                  handleCellClick(e)
                  setCustomerDetailData(params.row)
                  customerDetailModalOpen()
                }}
                className={'text-hover-danger cursor-pointer text-primary fw-bold text-truncate'}
              >
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
              <span className={'text-danger '}>
                {params.row.customerStatus === null
                  ? null
                  : params.row.customerStatus === 'as'
                  ? 'A/S'
                  : params.row.customerStatus === 'move'
                  ? '이전 철거'
                  : '철거'}
              </span>

              <div className={'fw-normal mt-8'}>
                {params.row.customerStatus === null ? (
                  <Button
                    variant={'outlined'}
                    color={'error'}
                    onClick={async (e) => {
                      handleCellClick(e)
                      requestAsNew(userInfo, params.row, getcustomerInfo)
                    }}
                  >
                    A/S
                  </Button>
                ) : params.row.customerStatus === 'as' ? (
                  <Button
                    variant={'outlined'}
                    color={'error'}
                    onClick={async (e) => {
                      handleCellClick(e)
                      requestAsCancelNew(userInfo, params.row, getcustomerInfo)
                    }}
                  >
                    A/S 취소
                  </Button>
                ) : params.row.customerStatus === 'move' ? (
                  '이전 철거'
                ) : (
                  '철거'
                )}
              </div>
            </div>
          </>
        )
      },
    },

    {
      field: 'customerInfo',
      headerName: '주소',
      renderCell: (params: any) => {
        return (
          <div className={'row text-truncate'}>
            <span className={'text-truncate'}>{params.row.customerName}</span>
            <span className='text-truncate'>
              <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.addressRoad}{' '}
            </span>
            <span className='text-truncate'>
              <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.addressNumber}{' '}
            </span>
            {params.row.addressDetail && (
              <span className='text-truncate'>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )}
            {params.row.managerName !== 'NULL' && (
              <span className='text-truncate'>
                <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 </strong> :{' '}
                {params.row.managerName}{' '}
              </span>
            )}
            <span className='text-truncate'>
              <strong style={{color: '#038109'}}>설치자 </strong> : {params.row.installerName}{' '}
            </span>
          </div>
        )
      },
      width: 350,
      flex: 2,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'deviceInfo',
      headerName: '배터리',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.serialNumber}</span>
            <span className={`${params.row.batteryLevel < 30 ? 'text-danger' : 'text-dark'}`}>
              {params.row.batteryLevel} %
            </span>
          </div>
        )
      },
      width: 150,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerDetail',
      headerName: '온도',
      width: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        const temp = params.row.temperature
        return (
          <div className={'row text-center'}>
            <span>{params.row.meterDiameter} mm</span>
            <span>{temp < 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },

    {
      field: 'efosStatus',
      headerName: '계량기',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <div
              className={'row w-100 h-100 text-center '}
              style={{alignContent: 'center'}}
              onClick={(e) => {
                handleCellClick(e)
                setDeviceDetailData(params.row)
                deviceDetailModalOpen()
              }}
            >
              <img
                src={
                  params?.row.efosStatus ? selectSuccessEfos(cooperIdx) : selectFailEFos(cooperIdx)
                }
                width={100}
                alt={'efos-check'}
              />
            </div>
          </>
        )
      },
      width: 120,

      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceStatus',
      headerName: '단말기',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx

        return (
          <>
            <div
              className={'row w-100 h-100 text-center '}
              style={{alignContent: 'center'}}
              onClick={(e) => {
                handleCellClick(e)
                setDeviceDetailData(params.row)
                deviceDetailModalOpen()
              }}
            >
              <img
                alt={'device-check'}
                src={
                  params.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
                }
                width={100}
              />
              {/* <span>{params.row.boardVersion === '22' ? 'v2.2' : 'v2.0'}</span> */}
            </div>
          </>
        )
      },
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,

      align: 'center',

      headerAlign: 'center',
      renderCell: (params: any) => {
        const rotate: string = params.row.rotate
        return (
          <>
            {params.row.deviceType !== 'analog' ? (
              <img
                className={'cursor-pointer'}
                src={defaultImage}
                alt={'default'}
                onClick={(e) => {
                  setDeviceDetailData(params.row)
                  handleCellClick(e)
                  imageDetailModalOpen()
                }}
              />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px', zIndex: 888}}
                >
                  {params.row.rotate !== 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}

                  <Chip
                    label={chipValue(params.row.recognizedType)}
                    color={chipColor(params.row.recognizedType)}
                    sx={{fontSize: '17px'}}
                  />
                </div>
                <img
                  alt={'device'}
                  className={'cursor-pointer'}
                  src={
                    params.row.filePath === null ? noImage : `${envImagePath}${params.row.filePath}`
                  }
                  style={{
                    transform: params.row.filePath
                      ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                        (Number(rotate) < -90 && Number(rotate) > -270)
                        ? `rotate(${rotate}deg)`
                        : `rotate(0deg)`
                      : `rotate(0deg)`,
                  }}
                  width={320}
                  height={144}
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    imageDetailModalOpen()
                  }}
                />
              </div>
            )}
          </>
        )
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침일',
      flex: 1,
      renderCell: (params: any) => {
        const status = params?.row?.processStatus
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
            {hiddenClick && (
              <div className='my-2'>
                <span className={`${status !== 'COMPLETE' ? 'text-warning' : ''}`}>{status}</span>
              </div>
            )}
            <span>{dayjs(params.row.efosConnectAt).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
      width: 160,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}

export const customerStatusColumns = (
  imageDetailModalOpen: () => void,
  customerDetailModalOpen: () => void,
  setCustomerDetailData: any,
  setDeviceDetailData: any,
  userInfo: any
) => {
  return [
    {
      field: 'customerNumber',
      headerName: '계량기 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        console.log('us', userInfo)
        return (
          <>
            <div className={'row w-100 h-100 text-center gap-2'} style={{alignContent: 'center'}}>
              <span
                onClick={(e) => {
                  handleCellClick(e)
                  setCustomerDetailData(params.row)
                  customerDetailModalOpen()
                }}
                className={'text-hover-danger cursor-pointer text-primary fw-bold text-truncate'}
              >
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
              <span className='text-truncate'>
                {params?.row.meterNumber !== '' ? params?.row.meterNumber : ' - '}
              </span>
              <span>{params?.row.cooperName}</span>
            </div>
          </>
        )
      },
    },

    {
      field: 'customerInfo',
      headerName: '주소',
      renderCell: (params: any) => {
        return (
          <div
            className='row'
            onClick={(e) => {
              handleCellClick(e)
              setCustomerDetailData(params.row)
            }}
            style={{
              width: '100%',
              overflow: 'hidden', // 전체 셀의 넘치는 내용을 숨김 처리
            }}
          >
            <span className='d-block w-100 text-truncate'>{params.row.customerName}</span>
            <span className='d-block w-100 text-truncate'>
              <strong className='text-primary'>도로명 </strong>: {params.row.addressRoad}
            </span>
            <span className='d-block w-100 text-truncate'>
              <strong style={{color: '#f37005'}}>지번 </strong>: {params.row.addressNumber}
            </span>
            {params.row.addressDetail && (
              <span className='d-block w-100 text-truncate'>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong>:{' '}
                {params.row.addressDetail}
              </span>
            )}
            <span className='d-block w-100 text-truncate'>
              <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 </strong>:{' '}
              {params.row.managerName}
            </span>
          </div>
        )
      },
      width: 240,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'deviceInfo',
      headerName: '배터리',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.serialNumber}</span>
            <span className={`${params.row.batteryLevel < 30 ? 'text-danger' : 'text-dark'}`}>
              {params.row.batteryLevel} %
            </span>
          </div>
        )
      },
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerDetail',
      headerName: '온도',
      width: 100,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        const temp = params.row.temperature
        return (
          <div className={'row text-center'}>
            <span>{params.row.meterDiameter} mm</span>

            <span>{temp < 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },

    {
      field: 'efosStatus',
      headerName: '계량기',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <img
              src={
                params?.row.efosStatus ? selectSuccessEfos(cooperIdx) : selectFailEFos(cooperIdx)
              }
              width={80}
            />
          </>
        )
      },
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceStatus',
      headerName: '단말기',
      renderCell: (params: any) => {
        const idxDate = params?.row.imageIdxDate || '202002020001'
        const checkToday = idxDate.slice(0, -4)
        const todayDate = dayjs().format('YYYYMMDD')
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            {entimoreController.model === '2' ? (
              <img
                alt={'yit_device'}
                src={checkToday === todayDate ? deviceSuccess : deviceError}
                width={80}
              />
            ) : (
              <img
                alt={'device_status'}
                src={
                  params?.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
                }
                width={80}
              />
            )}
          </>
        )
      },
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const rotate: string = params.row.rotate
        return (
          <>
            {params.row.deviceType !== 'analog' ? (
              <img
                className={'cursor-pointer'}
                alt={'default'}
                src={defaultImage}
                onClick={(e) => {
                  handleCellClick(e)
                  imageDetailModalOpen()
                  setDeviceDetailData(params.row)
                }}
              />
            ) : (
              <img
                className={'cursor-pointer'}
                alt={'check-device'}
                src={
                  params.row.filePath === null ? noImage : `${envImagePath}${params.row.filePath}`
                }
                style={{
                  transform: params.row.filePath
                    ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                      (Number(rotate) < -90 && Number(rotate) > -270)
                      ? `rotate(${rotate}deg)`
                      : `rotate(0deg)`
                    : `rotate(0deg)`,
                }}
                onClick={(e) => {
                  handleCellClick(e)
                  imageDetailModalOpen()
                  setDeviceDetailData(params.row)
                }}
              />
            )}
          </>
        )
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침일',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' || params.row.checkValue === null ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.efosConnectAt).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}

export const customerStatusBoseongColumns = (
  imageDetailModalOpen: () => void,
  customerDetailModalOpen: () => void,
  setCustomerDetailData: any,
  setDeviceDetailData: any,
  userInfo: any
) => {
  return [
    {
      field: 'customerNumber',
      headerName: '계량기 번호',
      minWidth: 180,
      flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            <div className={'row w-100 h-100 text-center gap-2'} style={{alignContent: 'center'}}>
              <span
                onClick={(e) => {
                  handleCellClick(e)
                  setCustomerDetailData(params.row)
                  if (userInfo.userInfo.accountType !== 'gov_meterman') {
                    customerDetailModalOpen()
                  }
                }}
                className={'text-hover-danger cursor-pointer text-primary fw-bold text-truncate'}
              >
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
              <span className='text-truncate'>
                {params?.row.meterNumber !== '' ? params?.row.meterNumber : ' - '}
              </span>
              <span>{params?.row.cooperName}</span>
            </div>
          </>
        )
      },
    },

    {
      field: 'customerInfo',
      headerName: '주소',
      width: 240,
      minWidth: 120,
      flex: 2,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div
            className='row'
            onClick={(e) => {
              handleCellClick(e)
              setCustomerDetailData(params.row)
            }}
            style={{
              width: '100%',
              overflow: 'hidden', // 전체 셀의 넘치는 내용을 숨김 처리
            }}
          >
            <span className='d-block w-100 text-truncate'>{params.row.customerName}</span>
            <span className='d-block w-100 text-truncate'>
              <strong className='text-primary'>도로명 </strong>: {params.row.addressRoad}
            </span>
            <span className='d-block w-100 text-truncate'>
              <strong style={{color: '#f37005'}}>지번 </strong>: {params.row.addressNumber}
            </span>
            {params.row.addressDetail && (
              <span className='d-block w-100 text-truncate'>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong>:{' '}
                {params.row.addressDetail}
              </span>
            )}
            <span className='d-block w-100 text-truncate'>
              <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 </strong>:{' '}
              {params.row.managerName}
            </span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      maxWidth: 300,
      minWidth: 200,
      flex: 2.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const rotate: string = params.row.rotate
        return (
          <>
            {params.row.deviceType !== 'analog' ? (
              <img
                className={'cursor-pointer'}
                alt={'default'}
                src={defaultImage}
                onClick={(e) => {
                  handleCellClick(e)
                  if (userInfo.userInfo.accountType !== 'gov_meterman') {
                    imageDetailModalOpen()
                  }
                  setDeviceDetailData(params.row)
                }}
              />
            ) : (
              <img
                className={'cursor-pointer'}
                alt={'check-device'}
                src={
                  params.row.filePath === null ? noImage : `${envImagePath}${params.row.filePath}`
                }
                style={{
                  transform: params.row.filePath
                    ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                      (Number(rotate) < -90 && Number(rotate) > -270)
                      ? `rotate(${rotate}deg)`
                      : `rotate(0deg)`
                    : `rotate(0deg)`,
                }}
                onClick={(e) => {
                  handleCellClick(e)
                  if (userInfo.userInfo.accountType !== 'gov_meterman') {
                    imageDetailModalOpen()
                  }

                  setDeviceDetailData(params.row)
                }}
              />
            )}
          </>
        )
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침일',
      minWidth: 170,
      flex: 1.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' || params.row.checkValue === null ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.efosConnectAt).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },

    {
      field: 'efosStatus',
      headerName: '계량기',
      width: 100,
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <img
              src={
                params?.row.efosStatus ? selectSuccessEfos(cooperIdx) : selectFailEFos(cooperIdx)
              }
              style={{
                width: '100%',
                height: 'auto',
                maxWidth: '100px',
                objectFit: 'contain',
              }}
            />
          </>
        )
      },
    },
    {
      field: 'deviceStatus',
      headerName: '단말기',
      width: 100,
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const idxDate = params?.row.imageIdxDate || '202002020001'
        const checkToday = idxDate.slice(0, -4)
        const todayDate = dayjs().format('YYYYMMDD')
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            {entimoreController.model === '2' ? (
              <img
                alt={'yit_device'}
                src={checkToday === todayDate ? deviceSuccess : deviceError}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100px',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <img
                alt={'device_status'}
                src={
                  params?.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
                }
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100px',
                  objectFit: 'contain',
                }}
              />
            )}
          </>
        )
      },
    },
    {
      field: 'deviceInfo',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      headerName: '배터리',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.serialNumber}</span>
            <span className={`${params.row.batteryLevel < 30 ? 'text-danger' : 'text-dark'}`}>
              {params.row.batteryLevel} %
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerDetail',
      headerName: '온도',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const temp = params.row.temperature
        return (
          <div className={'row text-center'}>
            <span>{params.row.meterDiameter} mm</span>

            <span>{temp < 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },
  ]
}

export const customerCheckListColumns: any = [
  {
    field: 'customerNumber',
    headerName: '수용가 번호',
    width: 180,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <div className='row text-center text-truncate gap-2'>
          <span className='text-truncate text-primary'>
            {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
          </span>
          <span className='text-truncate'>{params.row.cooperName}</span>
        </div>
      )
    },
  },

  {
    field: 'customerInfo',
    headerName: '주소',
    renderCell: (params: any) => {
      return (
        <div className={'row gap-2 text-truncate'}>
          <span className='text-truncate'>{params.row.customerName}</span>
          <span className='text-truncate'>
            <strong style={{color: 'rgba(86,31,13,0.7)'}}>주소 :</strong> {params.row.address}
          </span>
          <span className='text-truncate'>
            <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 :</strong> {params.row.managerName}
          </span>
        </div>
      )
    },
    width: 300,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'filePath',
    headerName: '검침 이미지',
    width: 1200,
    headerAlign: 'center',
    renderCell: (params: any) => {
      return (
        <div className={'custom-scrollbar overflow-auto w-100 h-100 pt-3 d-flex gap-6'}>
          {_.map(params.row.checkMeterList, (el, i) => {
            const rotate: string = el?.rotate
            return (
              <div key={i}>
                <div className={'row text-center mx-2'} style={{width: '250px'}}>
                  <div>{dayjs(el?.idxDate?.slice(0, 8))?.format('YYYY년 MM월 DD일')}</div>
                  <div>
                    <img
                      alt={'check-image'}
                      src={el?.filePath === null ? defaultImage : envImagePath + el?.filePath}
                      width={el?.filePath === null ? 80 : '250px'}
                      style={{
                        transform: el?.filePath
                          ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                            (Number(rotate) < -90 && Number(rotate) > -270)
                            ? `rotate(${rotate}deg)`
                            : `rotate(0deg)`
                          : `rotate(0deg)`,
                      }}
                    />
                  </div>
                  <div>
                    {el?.filePath !== null ? (
                      <span>검침값 : {el?.checkValue === '' ? '없음' : el?.checkValue}</span>
                    ) : (
                      <span>
                        검침값 :{' '}
                        {(Number(el?.checkValue) + Number(el?.checkDecimalValue))?.toFixed(3)}
                      </span>
                    )}
                  </div>
                  <div>
                    <span>사용량 : {el?.use}t</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    },
  },
]

export const customerCheckReportColumns: any = (
  checkReportList: any,
  updateValue: any,
  setUpdateValue: any,
  updateHandler: () => void
) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className='row text-truncate text-center gap-2'>
            <span className={'text-primary text-truncate'}>
              {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
            </span>
            <span className={'text-truncate'}>{params.row.cooperName}</span>
          </div>
        )
      },
    },
    {
      field: 'customerName',
      headerName: '정보',
      width: 280,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row px-0 mx-0 gap-3'}>
            <span>{params.row.customerName}</span>
            <span>
              <strong style={{color: 'rgba(86,31,13,0.7)'}}>주소 </strong>: {params.row.address}
            </span>
            <span>
              {' '}
              <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자</strong> :{' '}
              {params.row.managerName}
            </span>
          </div>
        )
      },
    },

    {
      field: 'meterDiameter',
      headerName: '구경',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.meterDiameter === '없음' ? '미등록' : params.row.meterDiameter + 'mm'}
          </span>
        )
      },
    },

    {
      field: 'checkDay',
      headerName: '검침일',
      width: 110,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{params.row.checkDay} 일</span>
      },
    },
    {
      field: 'startFilePath',
      headerName: '전월 이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: any) => {
        const startRotate: string = params.row.startRotate

        return (
          <>
            {params.row.deviceType === 'analog' &&
              (params.row.startCheckValue === null ? (
                <span className={'text-danger'}>검침 데이터 수집 중입니다.</span>
              ) : (
                <img
                  className={'p-3'}
                  src={envImagePath + params.row.startFilePath}
                  alt={'device'}
                  style={{
                    transform: params.row.startFilePath
                      ? (Number(startRotate) > 90 && Number(startRotate) < 270) ||
                        (Number(startRotate) < -90 && Number(startRotate) > -270)
                        ? `rotate(${startRotate}deg)`
                        : `rotate(0deg)`
                      : `rotate(0deg)`,
                  }}
                />
              ))}
            {params.row.deviceType === 'digital' &&
              (params.row.startCheckValue === null ? (
                <span className={'text-danger'}>검침 데이터 수집 중입니다.</span>
              ) : (
                <img className={'p-3'} src={defaultImage} alt={'default'} />
              ))}
          </>
        )
      },
    },
    {
      field: 'startIdxDate',
      headerName: '전월일',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.deviceType === 'analog' &&
              (params.row.startCheckValue === null ? (
                <span></span>
              ) : (
                <span>{dayjs(params.row.startIdxDate).format('YYYY년 MM월 DD일')}</span>
              ))}
            {params.row.deviceType === 'digital' &&
              (params.row.startCheckValue === null ? (
                <span></span>
              ) : (
                <span>{dayjs(params.row.startIdxDate).format('YYYY년 MM월 DD일')}</span>
              ))}
          </>
        )
      },
    },
    {
      field: 'endFilePath',
      headerName: '당월 이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: any) => {
        const endRotate: string = params.row.endRotate
        return (
          <>
            {params.row.deviceType === 'analog' &&
              (params.row.endCheckValue === null ? (
                <span className={'text-danger'}>검침 데이터 수집 중입니다.</span>
              ) : (
                <img
                  className={'p-3'}
                  src={envImagePath + params.row.endFilePath}
                  alt={'device'}
                  style={{
                    transform: params.row.endFilePath
                      ? (Number(endRotate) > 90 && Number(endRotate) < 270) ||
                        (Number(endRotate) < -90 && Number(endRotate) > -270)
                        ? `rotate(${endRotate}deg)`
                        : `rotate(0deg)`
                      : `rotate(0deg)`,
                  }}
                />
              ))}
            {params.row.deviceType === 'digital' &&
              (params.row.endCheckValue === null ? (
                <span className={'text-danger'}>검침 데이터 수집 중입니다.</span>
              ) : (
                <img className={'p-3'} src={defaultImage} alt={'default'} />
              ))}
          </>
        )
      },
    },
    {
      field: 'endIdxDate',
      headerName: '당월일',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.endCheckValue === null ? (
              <span></span>
            ) : (
              <span>{dayjs(params.row.endIdxDate).format('YYYY년 MM월 DD일')}</span>
            )}
          </>
        )
      },
    },
    {
      field: 'updateValue',
      headerName: '전월값 및 당월값 수정',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {checkReportList && params.row.cooperIdx === '1' && (
              <div className='d-flex justify-content-center gap-2'>
                <TextField
                  id='outlined-basic'
                  type={'text'}
                  label='전월값'
                  variant='outlined'
                  size='small'
                  className='mx-1'
                  defaultValue={params?.row?.startCheckValue}
                  onChange={(e) => {
                    setUpdateValue({
                      ...updateValue,
                      startImei: params.row.startImei,
                      startValue: e.target.value,
                      startIdxDate: params.row.startIdxDate,
                    })
                  }}
                />
                <TextField
                  id='outlined-basic'
                  type={'text'}
                  label='당월값'
                  variant='outlined'
                  size='small'
                  className='mx-1'
                  defaultValue={params?.row?.endCheckValue}
                  onChange={(e) => {
                    setUpdateValue({
                      ...updateValue,
                      imei: params.row.endImei,
                      value: e.target.value,
                      idxDate: params.row.endIdxDate,
                    })
                  }}
                />
                <Button
                  variant='contained'
                  size='small'
                  className='mx-1'
                  onClick={() => updateHandler()}
                >
                  수정
                </Button>
              </div>
            )}
            {checkReportList && params.row.cooperIdx !== '1' && (
              <div className='d-flex justify-content-center gap-2'>
                <TextField
                  id='outlined-basic'
                  type={'text'}
                  label='전월값'
                  variant='outlined'
                  size='small'
                  disabled
                  className='mx-1'
                  value={
                    Number(params?.row?.startCheckValue) +
                    Number(params?.row?.startCheckDecimalValue)
                  }
                />
                <TextField
                  id='outlined-basic'
                  type={'text'}
                  label='당월값'
                  variant='outlined'
                  size='small'
                  disabled
                  className='mx-1'
                  value={
                    Number(params?.row?.endCheckValue) + Number(params?.row?.endCheckDecimalValue)
                  }
                />
              </div>
            )}
          </>
        )
      },
    },
  ]
}
export const customerMinimumFlowColumns = (filter: any) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className='text-truncate text-center gap-2 row'>
            <span className={'text-primary text-truncate'}>
              {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
            </span>
            <span>{params.row.cooperName}</span>
          </div>
        )
      },
    },
    {
      field: 'customerName',
      headerName: '이름',
      width: 200,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className='text-truncate gap-2 row'>
            <span className='text-truncate'>{params.row.customerName}</span>
            <span className='text-truncate'>
              <strong style={{color: 'rgba(86,31,13,0.7)'}}>주소 </strong> :{params.row.address}
            </span>
            <span className='text-truncate'>
              <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 </strong> :{' '}
              {params.row.managerName}
            </span>
          </div>
        )
      },
    },
    {
      field: 'totalUsage',
      headerName: '총 사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <span>{Number(params.row.totalUsage)?.toFixed(3) + 't'}</span>
      },
    },
    {
      field: 'forOneHourUsage',
      headerName: '1시간 사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span>
            {'약 ' +
              (Number(params.row.totalUsage) / (filter.end - filter.start))?.toFixed(4) +
              't'}
          </span>
        )
      },
    },
    {
      field: 'checkMeterList',
      headerName: `${dayjs(filter.date).format('YYYY년 MM월 DD일')} / ${filter.start}시 ~ ${
        filter.end
      }시 검침이미지`,
      width: 1100,
      headerAlign: 'center',
      cellClassName: 'overflow-scroll',
      sortable: false,

      renderCell: (params: any) => {
        return (
          <div className={'custom-scrollbar overflow-auto w-100 h-100 pt-3 d-flex gap-6'}>
            {_.map(params.row.checkMeterList, (el, i) => {
              const rotate: string = el?.rotate
              return (
                <>
                  <div className={'row text-center mx-2'} style={{width: '250px'}}>
                    <div>{dayjs(el?.createAt).format('MM월 DD일 HH시 mm분')}</div>
                    <div>
                      <img
                        src={el?.filePath === null ? defaultImage : envImagePath + el?.filePath}
                        alt={'check-image'}
                        style={{
                          transform: el?.filePath
                            ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                              (Number(rotate) < -90 && Number(rotate) > -270)
                              ? `rotate(${rotate}deg)`
                              : `rotate(0deg)`
                            : `rotate(0deg)`,
                        }}
                        width={el?.filePath === null ? 80 : '250px'}
                      />
                    </div>
                    <div>
                      {el?.filePath !== null ? (
                        <span>
                          검침값 :<u> {el.checkValue === '' ? '없음' : el?.checkValue} </u>
                        </span>
                      ) : (
                        <span>
                          검침값 :{' '}
                          <u>
                            {(Number(el.checkValue) + Number(el.checkDecimalValue))?.toFixed(3)}
                          </u>
                        </span>
                      )}
                    </div>
                    <div>
                      <span>사용량 : {el.use}t</span>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        )
      },
    },
  ]
}

export const customerLeakageColumns = (
  filter: any,
  imageDetailModalOpen: () => void,
  setDeviceDetailData: any
) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
            </span>
          </>
        )
      },
    },

    {
      field: 'customerName',
      headerName: '주소',
      renderCell: (params: any) => {
        return (
          <div className={'row text-start'}>
            <span>{params.row.customerName}</span>
            <span>{params.row.addressRoad}</span>
          </div>
        )
      },
      width: 250,
      align: 'left',
      headerAlign: 'center',
    },

    {
      field: 'leakFilePath',
      headerName: '이미지',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.todayFilePath === '' ||
            params.row.todayFilePath === null ||
            params.row.todayFilePath === 'null' ? (
              <div className={'row text-center justify-content-center gap-2'}>
                <img
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    // imageDetailModalOpen()
                  }}
                  src={defaultImage}
                  style={{width: '80px'}}
                  className={'cursor-pointer'}
                />
                <span className={'text-danger'}>
                  누수의심 기준 : 약 {Number(params.row.avg).toFixed(3)}t
                </span>
              </div>
            ) : (
              <div className={'row text-center justify-content-center gap-2'}>
                <img
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    // imageDetailModalOpen()
                  }}
                  className={'cursor-pointer'}
                  src={envImagePath + params.row.todayFilePath}
                />
                <span className={'text-danger'}>
                  누수의심 기준 : 약 {Number(params.row.avg).toFixed(3)}t
                </span>
              </div>
            )}
          </>
        )
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침정보',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{dayjs(params.row.todayIdxDate).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'checkList',
      headerName: '차트',
      renderCell: (params: any) => {
        return (
          <LeakViewChart
            className={'p-3 w-100'}
            filter={filter}
            checkList={params.row.checkList}
            avg={params.row.avg}
          />
        )
      },
      width: 1330,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}
export const customerLeakageColumnsBongHwa = (
  filter: any,
  imageDetailModalOpen: () => void,
  setDeviceDetailData: any
) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호/ 주소',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            <div className={'row text-center text-truncate gap-2'}>
              <span
                className={'text-hover-danger cursor-pointer text-primary fw-bold text-truncate'}
              >
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
              <span className={'text-truncate'}>{params.row.customerName}</span>
              <span className={'text-truncate'}>{params.row.addressRoad}</span>
            </div>
          </>
        )
      },
    },
    {
      field: 'leakFilePath',
      headerName: '이미지',
      width: 240,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.todayFilePath === '' ||
            params.row.todayFilePath === null ||
            params.row.todayFilePath === 'null' ? (
              <div className={'row text-center justify-content-center gap-2'}>
                <img
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    // imageDetailModalOpen()
                  }}
                  src={defaultImage}
                  style={{width: '80px'}}
                  className={'cursor-pointer'}
                />
              </div>
            ) : (
              <div className={'row text-center justify-content-center gap-2'}>
                <img
                  onClick={(e) => {
                    setDeviceDetailData(params.row)
                    handleCellClick(e)
                    // imageDetailModalOpen()
                  }}
                  className={'cursor-pointer'}
                  src={envImagePath + params.row.todayFilePath}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'leakUsed',
      headerName: '사용량',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const cycles =
          params.row.leakUseCycle === '일'
            ? '1일'
            : params.row.leakUseCycle === '주'
            ? '1주일'
            : '1개월'
        const startDate = dayjs(params.row.calStartDate).format('MM월 DD일')
        const endDate = dayjs(params.row.calEndDate).format('MM월 DD일')
        const today = dayjs(params.row.todayIdxDate.slice(0, 8)).format('MM월 DD일')
        return (
          <>
            <div className={'row text-start gap-2'}>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                title={
                  <span className={'fs-6'}>
                    {startDate}부터 {endDate}까지 사용량
                  </span>
                }
              >
                <span className={'cursor-pointer text-primary fw-bold'}>
                  지난 {cycles} 사용량 : {params.row.usedAmountUntilRecent}t
                </span>
              </Tooltip>

              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{timeout: 600}}
                title={
                  <span className={'fs-6'}>
                    {endDate}부터 {today}까지 사용량
                  </span>
                }
              >
                <span>
                  최근 {cycles} 사용량 : {params.row.usedAmountUntilToday}t
                </span>
              </Tooltip>
            </div>
          </>
        )
      },
    },

    {
      field: 'checkList',
      headerName: '차트',
      width: 1330,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <LeakViewChartBongHwa
            className={'p-3 w-100'}
            filter={filter}
            checkList={params.row.checkList}
            avg={params.row.avg}
          />
        )
      },
    },
  ]
}

export const customerLeakageDetailUncertainListColumns = (filter: any) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호 / 이름',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate fs-5'}>
            <div className={'text-primary'}>
              {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
            </div>
            <div className={'text-truncate '}>{params.row.customerName}</div>
          </div>
        )
      },
    },
    {
      field: 'customerAddress',
      headerName: '주소',
      width: 420,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate fs-5'}>
            <div className={'text-truncate'}>{params.row.address}</div>
          </div>
        )
      },
    },
    {
      field: 'customerLatestImage',
      headerName: '마지막 검침 이미지',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      sortable: false,

      renderCell: (params: any) => {
        return (
          <div className={'row text-center fs-5'}>
            <img
              src={`${envImagePath}${params.row.filePath}`}
              alt='id-logo'
              className={'img-fluid'}
              loading={'lazy'}
            />
            <span>{dayjs(params.row.lastIdxDate.slice(0, 8)).format(`YYYY년 MM월 DD일`)}</span>
          </div>
        )
      },
    },
  ]
}

export const customerLongTermUnusedColumn = (filter: any) => {
  return [
    {
      field: 'customerNumber',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate fs-5'}>
            <div className={'text-primary'}>
              {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
            </div>
          </div>
        )
      },
    },
    {
      field: 'customerName',
      headerName: '이름',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate fs-5'}>
            <div className={'text-truncate '}>{params.row.customerName}</div>
          </div>
        )
      },
    },

    {
      field: 'customerAddress',
      headerName: '주소',
      width: 420,
      align: 'center',
      headerAlign: 'center',
      sortable: false,

      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate fs-5'}>
            <div className={'text-truncate '}>{params.row.address}</div>
          </div>
        )
      },
    },
    {
      field: 'customerImage ',
      headerName: '이미지',
      width: 600,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'d-flex gap-4'}>
            <div className={'row text-center fs-5 gap-2'}>
              <img
                src={`${envImagePath}${params.row.longtermDayFilePath}`}
                alt='id-logo'
                className={'img-fluid'}
                loading={'lazy'}
              />
              <span>
                검침값 :{' '}
                <span style={{textDecoration: 'underline'}}>
                  {params.row.longtermDayCheckValue}
                </span>
              </span>
              <span>
                {dayjs(params.row.longtermDayIdxDate && params?.row?.longtermDayIdxDate).format(
                  `YYYY년 MM월 DD일`
                )}
              </span>
            </div>
            <div className={'row text-center fs-5 gap-2'}>
              <img
                src={`${envImagePath}${params.row.todayFilePath}`}
                alt='id-logo'
                className={'img-fluid'}
                loading={'lazy'}
              />
              <span>
                검침값 :{' '}
                <span style={{textDecoration: 'underline'}}>{params.row.todayCheckValue}</span>
              </span>
              <span>
                {dayjs(params.row.todayIdxDate && params?.row?.todayIdxDate).format(
                  `YYYY년 MM월 DD일`
                )}
              </span>
            </div>
          </div>
        )
      },
    },
  ]
}

export const customerFreezeWarningColumns = (filter: any) => {
  const chipValue = (value: string) => {
    switch (value) {
      case null:
        return '기계식'
      case 'analog':
        return '기계식'
      case 'digital':
        return '디지털'
      case 'gas':
        return '가스'
      default:
        return '기계식'
    }
  }
  const chipColor = (value: string) => {
    switch (value) {
      case null:
        return 'error'
      case 'analog':
        return 'error'
      case 'digital':
        return 'warning'
      case 'gas':
        return 'success'
      default:
        return 'error'
    }
  }
  return [
    {
      field: 'customerNumber',
      headerName: '동파 구분',
      width: 180,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        const temp = params.row.currentTemperature
        return (
          <div className='row gap-2'>
            <div className={'row w-100 h-100 text-center gap-2'} style={{alignContent: 'center'}}>
              <span className={'text-primary text-truncate fw-bold'}>
                {globalFormatWithHyphens(params.row.officeId, params.row.customerNumber)}
              </span>
              <span className={'text-truncate'}>{params.row.cooperName}</span>
            </div>
            <div className={'row text-center'}>
              {temp < 90 ? (
                temp > -1 ? (
                  <span className={'text-primary'}>양호</span>
                ) : temp >= -3 ? (
                  <span className={'text-warning'}>주의</span>
                ) : temp >= -5 ? (
                  <span style={{color: 'orange'}}>위험</span>
                ) : (
                  <span className={'text-danger'}>매우위험</span>
                )
              ) : (
                <span className={''}>-</span>
              )}
            </div>
          </div>
        )
      },
    },
    {
      field: 'officeName',
      headerName: '사업소 명',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center text-truncate'}>
            <span className={'text-truncate'}>{params.row.officeId}</span>
            <span className={'text-truncate'}>{params.row.officeName}</span>
          </div>
        )
      },
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'customerInfo',
      headerName: '주소',
      renderCell: (params: any) => {
        return (
          <div className={'row text-truncate'}>
            <span className={'text-truncate'}>{params.row.customerName}</span>
            <span className={'text-truncate'}>
              <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.addressRoad}{' '}
            </span>
            <span className={'text-truncate'}>
              <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.addressNumber}{' '}
            </span>
            {params.row.addressDetail && (
              <span className={'text-truncate'}>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )}
            {params.row.managerName !== 'NULL' && (
              <span className={'text-truncate'}>
                <strong style={{color: 'rgba(29,61,37,0.7)'}}>담당자 </strong> :{' '}
                {params.row.managerName}{' '}
              </span>
            )}
          </div>
        )
      },
      width: 260,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'deviceInfo',
      headerName: '배터리',
      renderCell: (params: any) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.serialNumber}</span>
            <span className={`${params.row.batteryLevel < 30 ? 'text-danger' : 'text-dark'}`}>
              {params.row.batteryLevel} %
            </span>
          </div>
        )
      },
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'customerDetail',
      headerName: '온도',
      width: 140,
      align: 'center',
      headerAlign: 'center',

      renderCell: (params: any) => {
        const temp = params.row.currentTemperature
        return (
          <div className={'row text-center'}>
            <span>{params.row.meterDiameter} mm</span>
            <span>{temp < 90 ? `${temp}°C` : '-'}</span>
          </div>
        )
      },
    },

    {
      field: 'efosStatus',
      headerName: '계량기',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <div className={'row w-100 h-100 text-center '} style={{alignContent: 'center'}}>
              <img
                src={
                  params?.row.efosStatus ? selectSuccessEfos(cooperIdx) : selectFailEFos(cooperIdx)
                }
                width={80}
                alt={'meter-check'}
              />
            </div>
          </>
        )
      },
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'deviceStatus',
      headerName: '단말기',
      renderCell: (params: any) => {
        const cooperIdx = params?.row.cooperIdx
        return (
          <>
            <div className={'row w-100 h-100 text-center '} style={{alignContent: 'center'}}>
              <img
                alt={'device-check'}
                src={
                  params.row.deviceStatus ? selectSuccessImg(cooperIdx) : selectFailImg(cooperIdx)
                }
                width={80}
              />
            </div>
          </>
        )
      },
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 340,
      align: 'center',

      headerAlign: 'center',
      renderCell: (params: any) => {
        const rotate: string = params.row.rotate
        return (
          <>
            {params.row.deviceType !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} alt={'default'} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px', zIndex: 888}}
                >
                  {params.row.rotate !== 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}

                  <Chip
                    label={chipValue(params.row.recognizedType)}
                    color={chipColor(params.row.recognizedType)}
                    sx={{fontSize: '17px'}}
                  />
                </div>
                <img
                  alt={'device'}
                  className={'cursor-pointer'}
                  src={
                    params.row.filePath === null ? noImage : `${envImagePath}${params.row.filePath}`
                  }
                  style={{
                    transform: params.row.filePath
                      ? (Number(rotate) > 90 && Number(rotate) < 270) ||
                        (Number(rotate) < -90 && Number(rotate) > -270)
                        ? `rotate(${rotate}deg)`
                        : `rotate(0deg)`
                      : `rotate(0deg)`,
                  }}
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },

    // {
    //   field: 'checkInfo',
    //   headerName: '검침일',
    //   renderCell: (params: any) => {
    //     return (
    //       <div className={'row text-center'}>
    //         <div className={'d-flex justify-content-center'}>
    //           <span>
    //             {params.row.checkValue === '' ? (
    //               <span className={'text-danger'}>인식불가</span>
    //             ) : (
    //               params.row.checkValue
    //             )}
    //           </span>
    //           <span>
    //             {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
    //               ? ''
    //               : '.' + params.row.checkDecimalValue?.split('.')[1]}
    //           </span>
    //         </div>
    //         <span>{dayjs(params.row.efosConnectAt).format('YYYY-MM-DD')}</span>
    //       </div>
    //     )
    //   },
    //   width: 170,
    //   align: 'center',
    //   headerAlign: 'center',
    // },
    {
      field: 'temperatureHisotry',
      headerName: '온도 추이',
      renderCell: (params: any) => {
        return (
          <TemperatureHistoryChart className={'p-0'} checkList={params?.row?.temperatureList} />
        )
      },
      width: 600,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}
